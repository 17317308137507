import React from 'react';
import { Tree, TreeNode } from 'react-organizational-chart';
import BFCapital from '../../assets/images/bridgeford.png';
import Tsigiro from '../../assets/images/tsig.png';
import Nafuico from '../../assets/images/nafuico.png';
import Agrostrong from '../../assets/images/agrostrong.png';
import Jambo from '../../assets/images/jamboo.png';
import Sendhome from '../../assets/images/sendhome.png';
import Misty from '../../assets/images/misty.png';
import News from '../../assets/images/news.png';


const CompanyStructure = (props) => {
    return (
        <Tree
            lineHeight='30px'
            lineWidth='2px'
            lineColor={'#e3944a'}
            label={
                <div>
                    <img src={BFCapital} style={{ height: 80 }} /><br />
                    To be listed on the Victoria Stock Exchange <br />
                    (Zimbabwe)
                </div>
            }>
            <TreeNode label={<div><strong>100%</strong></div>}>
                <TreeNode label={
                    <div style={{ padding: 10, border: '2px solid #e3944a', borderRadius: 10 }}>
                        <span style={{ fontSize: 20, fontWeight: '800', color: '#1e4d92' }}>BridgeFort Holdings (Pty) Ltd </span>  <br />
                        South Africa
                    </div>
                } >
                    {/* Tsigiro Usekelo */}
                    <TreeNode label={
                        <div>
                            <strong>100%</strong>
                        </div>
                    }>
                        <TreeNode label={<div>
                            <img src={Tsigiro} style={{ height: 50 }} />
                        </div>} >
                            <TreeNode label={<div>
                                <p style={{ fontWeight: '600' }}>Financial Services Platform</p>
                            </div>} >
                                <TreeNode label={<div>
                                    Wealth Management<br />
                                    Insurance <br />
                                    Investments <br />
                                    Remittances <br />
                                    Payments and Collections <br />
                                </div>} >
                                    <TreeNode label={<div>
                                        <a href='/' style={{ textDecoration: 'none' }} target='_blank'>
                                            <button>
                                                Learn more
                                            </button>
                                        </a>
                                    </div>} />
                                </TreeNode>
                            </TreeNode>

                        </TreeNode>


                    </TreeNode>
                    {/* Nafuico */}
                    <TreeNode label={<div><strong>50%</strong></div>}>
                        <TreeNode label={<div>
                            <img src={Nafuico} style={{ height: 40 }} />
                        </div>} >
                            <TreeNode label={<div>
                                <p style={{ fontWeight: '600' }}>Insurance and Funeral Services</p>

                            </div>} >
                                <TreeNode label={<div>
                                    Local Repratriations <br />
                                    International Repratriations <br />
                                    Funeral Services <br />
                                    Funeral Cover <br />
                                </div>} >
                                    <TreeNode label={<div>
                                        <a href='https://www.nafuico.co.za/' style={{ textDecoration: 'none' }} target='_blank'>
                                            <button>
                                                Learn more
                                            </button>
                                        </a>
                                    </div>} />
                                </TreeNode>
                            </TreeNode>
                        </TreeNode>
                    </TreeNode>
                </TreeNode>

            </TreeNode>


            <TreeNode label={<div></div>}>

                {/* Agrostrong */}
                <TreeNode label={<div><strong>100%</strong></div>}>
                    <TreeNode label={<div>
                        <img src={Agrostrong} style={{ height: 40 }} />
                    </div>} >
                        <TreeNode label={<div>

                            <p style={{ fontWeight: '600' }}>Cattle backed financial services</p>
                        </div>} >
                            <TreeNode label={<div>
                                Cattle backed financial <br />services
                            </div>} >
                                <TreeNode label={<div>
                                    <a href='https://agrostrong.net/' style={{ textDecoration: 'none' }} target='_blank'>
                                        <button>
                                            Visit Website
                                        </button>
                                    </a>
                                </div>} />
                                <TreeNode label={<div>
                                    <a href='https://agrostrong.fra1.digitaloceanspaces.com/AgroStrong%20-Bronchure-09.11.2023.pdf' style={{ textDecoration: 'none' }} target='_blank'>
                                        <button>
                                            Customer Offering
                                        </button>
                                    </a>
                                </div>} />
                            </TreeNode>
                        </TreeNode>
                    </TreeNode>
                </TreeNode>
                {/* Jamboo*/}
                <TreeNode label={<div><strong>20%</strong></div>}>
                    <TreeNode label={<div>
                        <img src={Jambo} style={{ height: 40 }} />
                    </div>} >
                        <TreeNode label={<div>
                            <p style={{ fontWeight: '600' }}>Jamboo</p>
                        </div>}>
                            <TreeNode label={<div>
                                Nano Bank <br />
                                Worldwide remittances<br />
                                African Diaspora link: EU & USA
                            </div>} >
                                <TreeNode label={<div>
                                    <a href='https://jamboo.app/' style={{ textDecoration: 'none' }} target='_blank'>
                                        <button>
                                            Learn more
                                        </button>
                                    </a>
                                </div>} />
                            </TreeNode>
                        </TreeNode>

                    </TreeNode>
                </TreeNode>
                {/* Sendhome */}
                <TreeNode label={<div><strong>20%</strong></div>}>
                    <TreeNode label={<div>
                        <img src={Misty} style={{ height: 60 }} /><br />
                        <strong>Zimbabwe</strong>
                    </div>} >
                        <TreeNode label={<div>
                            <p style={{ fontWeight: '600' }}>Memorial Park</p>
                        </div>}>
                            <TreeNode label={<div>
                                Legacy Memorial Park <br />
                                Diaspora Offering<br />
                                Local Offering
                            </div>} >
                            </TreeNode>
                        </TreeNode>
                    </TreeNode>
                </TreeNode>

            </TreeNode>
        </Tree>
    )
}

export default CompanyStructure;