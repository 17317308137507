import React from 'react';
import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import BlacknWhiteLogo from '../../assets/images/blacknwhitelogo.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faLinkedin, faInstagram, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom';
import IPOS from '../../api/IPOS';
import { useState } from 'react';


const Footer = () => {
    const [state, setState] = useState({})
    const subscribe = (e) => {
        e.preventDefault();
        IPOS.subscribe(state).then(done=>{
            setState({});
            NotificationManager.success(done.data.message, 'Successfully Subscribed');
        }).catch(error=>{
            console.log(error)
            NotificationManager.error('News letter subscription failed', 'Failed to subscribe', 5000, () => {});
        })
    }
    const handleInput = (e) =>{
        setState({...state, [e.target.name] : e.target.value})
    }
  return (
    <div className='footer-container'>
    <NotificationContainer/>
    <div className='footer'>
        <div className='footer-left'>
            <img src={BlacknWhiteLogo} alt="tsigiro-logo-bnw"/>
            <p>Join our newsletter and stay up to date on features and releases</p>
            <div className='subscription-container'>
                <form onSubmit={subscribe}>
                    <input required value = {state?.email} onChange={handleInput} type="text" name="email"/>
                    <button type='submit' className='btn btn-secondary'>
                        Submit
                    </button>
                </form>
            </div>
            <p style={{fontSize:'smaller'}}>By subscribing you agree to with our <span>Privacy Policy</span> and provide consent to receive updates from our company.</p>
        </div>
        <div className='footer-right'>
            <div className='footer-links'>
                    <p className='title'>Products</p>
                    <Link to="/properties"  style={{textDecoration:'none',color:'#fff'}}><p>Property</p></Link>
                    <Link to="/investments" style={{textDecoration:'none',color:'#fff'}}><p>Investments</p></Link>
                    <Link to="/funeral-cover" style={{textDecoration:'none',color:'#fff'}}><p>Funeral Cover</p></Link>
                    {/* <a href='https://ahoyiafrica.com/' target='blank' rel='noopener' style={{textDecoration:'none',color:'#fff'}}><p>Groceries</p></a> */}
                    <a href='http://systech.tsigiro.com/' target='blank' rel='noopener' style={{textDecoration:'none',color:'#fff'}}><p>Construction Materials</p></a>
            </div>
            <div className='footer-links' >
                    <p className='title'>About Tsigiro</p>
                    <Link to="/about-us" style={{textDecoration:'none',color:'#fff'}}><p>About</p></Link>
                    <Link to="/partners" style={{textDecoration:'none',color:'#fff'}}><p>Partners</p></Link>
                    <Link to="/contact" style={{textDecoration:'none',color:'#fff'}}><p>Contact</p></Link>
                    <Link to="/board-of-advisors" style={{textDecoration:'none',color:'#fff'}}><p>Advisors</p></Link>
                    <Link to="/gallery" style={{textDecoration:'none',color:'#fff'}}><p>Gallery</p></Link>

            </div>            
             <div className='footer-links' >
             <p className='title'>Follow Us</p>
             <a href="https://www.facebook.com/DKtsigirousekelo" style={{textDecoration:'none',color:'#fff'}} target="_blank" rel="noreferrer"><p> <FontAwesomeIcon icon={faFacebook} size="lg" className='faviconFooter'/>  Facebook</p></a>
             <a href="https://www.linkedin.com/company/tsigirousekelo/" style={{textDecoration:'none',color:'#fff'}} target="_blank" rel="noreferrer"><p> <FontAwesomeIcon icon={faLinkedin} size="lg" className='faviconFooter'/>  LinkedIn</p></a>
             <a href="https://www.instagram.com/tsigiro_usekelo/?igshid=ZDdkNTZiNTM%3D" style={{textDecoration:'none',color:'#fff'}} target="_blank" rel="noreferrer"><p> <FontAwesomeIcon icon={faInstagram} size="lg" className='faviconFooter'/>  Instagram</p></a>
             <a href="https://twitter.com/TsigiroUsekelo" style={{textDecoration:'none',color:'#fff'}} target="_blank" rel="noreferrer"><p> <FontAwesomeIcon icon={faTwitter} size="lg" className='faviconFooter'/>  Twitter</p></a>
             <a href="https://www.youtube.com/channel/UCnV8WlCNEAlHaXo-qUo7rVA" style={{textDecoration:'none',color:'#fff'}} target="_blank" rel="noreferrer"><p> <FontAwesomeIcon icon={faYoutube} size="lg" className='faviconFooter'/>  Youtube</p></a>
            </div>
            
        </div>
       
    </div>
  
    <div class='footer-links-legal'>
    <h6 class='title'>Legal</h6>
    {/* <p>We are currently using certificates from one of our partners - Agrostrong</p> */}
    <div class='legal-info'>
        <div class='legal-info-column'>
            <p>South Africa</p>  
            <a href="https://agrostrong.fra1.digitaloceanspaces.com/SA%20-%20TSIGIRO%20-%20NCRP17321%20(JUL-2025).pdf" target="_blank" style={{color:'#fff'}}> <p>Tsigiro Usekelo is a registered credit provider in terms of the National Credit Act, registration number NCRCP17321. View Certificate</p></a>  
            <a href="https://agrostrong.fra1.digitaloceanspaces.com/21st%20Century%20life%20FSCA%20license.pdf" target="_blank" style={{color:'#fff'}}><p>Tsigiro | Usekelo  is a Juristic Rep of 21st Century Life an Authorized FSP 35947 . View Certificate</p></a>
            <a href="https://agrostrong.fra1.digitaloceanspaces.com/SA%20-%20AGROSTRONG%20-%20NCRP15343%20(JUL-2025).pdf" target="_blank" style={{color:'#fff'}}><p>AgroStrong is a registered credit provider NCRCP15343. View certificate</p></a>
            <a href="https://agrostrong.net/wp-content/uploads/2022/06/APAC-Certificates.pdf" target="_blank" style={{color:'#fff'}}><p>Regulated by the Agricultural Produce Agents Council Act: Reg 2021/756349/07. View Certificate</p></a>
            <a href="https://agrostrong.net/wp-content/uploads/2022/06/FSCA-iEnsure-Digital.pdf" target="_blank" style={{color:'#fff'}}><p>AgroStrong is a Juristic Rep of iEnsure Digital an Authorized FSP 47615. View Certificate</p></a>
        </div>
        <div class='legal-info-column' style={{marginLeft: "10%"}}>
            <p>Zimbabwe</p>
            <a href="https://agrostrong.fra1.digitaloceanspaces.com/Sec%20Registration%20Certificate.pdf " style={{color:'#fff'}}><p>Tsigiro | Usekelo Funds are managed by Purpose Asset Management, a registered Asset Manager License number SECZ3413A.  </p></a>
            <a href="https://agrostrong.net/wp-content/uploads/2022/07/1.-AMA-Licence-2022-1.pdf" style={{color:'#fff'}}><p>Agrostrong is registered with the Agricultural Marketing Authority. view certificates</p></a>
            <a href="https://agrostrong.net/wp-content/uploads/2022/06/ipec.pdf" style={{color:'#fff'}}><p>Agrostrong is registered with the Insurance & Pensions commission . view certificates</p></a>
        </div>
    </div>
    </div>
    
                       
   
     <div className='footer-bottom'>  
        
        <p>{new Date().getFullYear()} Tsigiro Usekelo All Right Reserved</p>
        <div className='privacy-links'>
        <a href="/privacy-policy" style={{textDecoration:'none',color:'#fff'}} ><p>Privacy Policy</p></a>
            <a href="/terms-of-service" style={{textDecoration:'none',color:'#fff'}} ><p>Terms of service</p></a>
            {/* <p>Cookie settings</p> */}
        </div>
    </div>
    
    {/* <div className='footer-left-disclaimer'>
        <p style={{fontSize: "12px"}}><u>Disclaimer</u> – This message and any attachments are confidential and intended solely for the addressee. If you have received this message in error, please notify Tsigiro Usekelo Pty Ltd (“Tsigiro Usekelo”) immediately, by submitting a ticket here. Any unauthorized use, alteration or dissemination of the contents of this email is strictly prohibited. In no event will Tsigiro Usekelo or the sender be liable in any manner whatsoever to any person for any loss or any direct, indirect, special or consequential damages arising from use of this email or any linked website, including, without limitation, from any lost profits, business interruption, loss of programmes or other data that may be stored on any information handling system or otherwise from any assurance that this email is virus free even if Tsigiro Usekelo is expressly advised of the possibility of such damages. Tsigiro Usekelo is an authorised financial services provider (FSP no. XXXXXXX) and a registered credit provider (NCRCPXXXXXX). This document is a marketing communication. The information contained in this document should not be considered a recommendation to purchase or sell any particular financial product. Nothing contained herein should be construed as any form of financial advice.</p>
    </div> */}
    </div>
  )
}

export default Footer