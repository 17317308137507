import React from "react";
import { FullPage } from "react-full-page";
import Navbar from "../components/navbar/Navbar";
import Footer from "../components/footer/Footer";
import First from "../components/faqs-page/First";
import FAQ from "../components/faqs/FAQ";

function Faqs() {
  return (
    <div>
      <FullPage>
        <Navbar />
        <First />
        <Footer />
      </FullPage>
    </div>
  );
}

export default Faqs;
