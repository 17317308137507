import React from "react";
import { ModulesLowerData } from "../../constants/ModulesLowerData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

const ModulesTwo = () => {
  return (
    <div className="modules-cta-container">
      <p
        style={{
          fontSize: "14px",
          color: "#D47721",
          fontWeight: "500",
          lineHeight: "120%",
          textAlign: "center",
        }}
      >
        Start your investment journey
      </p>
      <p
        className="larger-text"
        style={{
          fontSize: "48px",
          color: "#295052",
          fontWeight: "500",
          lineHeight: "120%",
          textAlign: "center",
        }}
      >
        Investing has never been easier
      </p>
      <p
        className="larger-text"
        style={{
          fontSize: "12px",
          lineHeight: "120%",
          marginTop: "20px",
        }}
      >
        We care for your wellbeing and smart investment is part of it, so we
        have made this process as easy as pie, start investing now!
      </p>
      <p style={{ margin: 0, textAlign: "center" }}></p>
      <div className="modules-cta-section">
        {ModulesLowerData.map((x, index) => {
          return (
            <div className="ribbon-cta-container">
              <div key={index} className="single-cta-module">
                <img src={x.icon} alt={"tsigiro" + x.title} />
                <h3 style={{fontWeight:'500'}}>{x.title}</h3>
                <p style={{fontSize: "14px"}}>{x.description}</p>
              </div>
            </div>
          );
        })}
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: "5px",
        }}
      >
        <a href="https://id.tsigiro.com">
          <button
            className="btn btn-secondary"
            style={{ marginTop: "30px", marginBottom: "30px" }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              <p style={{ margin: 0 }}>Start Investing</p>
              {/* <FontAwesomeIcon icon={faChevronRight} /> */}
            </div>
          </button>
        </a>
      </div>
    </div>
  );
};

export default ModulesTwo;
