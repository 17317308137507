export const TsigiroProducts = [
{
    title:'Tsigiro Usekelo Trust Fund',
    description:'Our company is collaborating with top financial institutions in Zimbabwe to establish a Tsigiro Usekelo Trust Fund. The primary objective is to pool resources, ensuring that financially excluded members of society can participate and lead healthy financial lives. Our main focus is to provide housing, and we are currently addressing the following:',
    features: ["Reducing the required deposits by financial institutions.","Prolonged repayment periods.","Developing properties with appropriate infrastructure."],
    className:'tsigiro-trust-fund'
},

{
    title:'Cattle Investments',
    description:'Investments in cattle allow for the initial capital to not just maintain its value, but to grow in-line with or ahead of inflation. Here are some use cases:',
    features: ["Use your cattle as security for loan.","Use your cattle as deposit for your property purchase.","Use cattle as short-term investment or long term.","Build your wealth overtime."],
    className:'tsigiro-cattle-units'
}
]