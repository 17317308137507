import React from 'react';
import { EChart } from '@hcorta/react-echarts';

const RevenueChart = () => {
  return (
    <EChart
        className={'chart'}
        title={{
              text: 'Gross Revenue',
              textStyle:{
                fontSize:12,
              },
              left: "center",
            }}
        legend={{top:30}}
        xAxis={{
        type: 'category'
        }}
        style={{
            height: 450,
          }}
        yAxis={{
        type: 'value',
        boundaryGap: false,
        offset:-15,
        maxInterval:500000
        }}
        
        tooltip={{
            trigger: 'axis'
        }}
        series={[
        {
            type: 'bar',
            name:'Gross Revenue (US$)',
            color:'#4e81bd',
            data: [
            ['2019', (1467204/19).toFixed(2)],
            ['2020', (1200974/19).toFixed(2)],
            ['2021', (1795737/19).toFixed(2)],
            ['2022', (1680532/19).toFixed(2)],
            ['2023', (1380416/19).toFixed(2)],
            ['2024', (18899903/19).toFixed(2)],
            ['2025', (30853487/19).toFixed(2)],
            ['2026', (52429961/19).toFixed(2)],
            ['2027', (77359754/19).toFixed(2)],
            ['2028', (101088156/19).toFixed(2)],
            ]
        },
        // {
        //     type: 'bar',
        //     name:'Net Profit after tax (US$)',
        //     color:'#c0504d',
        //     data: [
        //         ['2019', (12001/19).toFixed(2)],
        //         ['2020',(129127/19).toFixed(2)],
        //         ['2021', (38555/19).toFixed(2)],
        //         ['2022', (898312/19).toFixed(2)],
        //         ['2023', (160147/19).toFixed(2)],
        //         ['2024', (1436018/19).toFixed(2)],
        //         ['2025', (7969129/19).toFixed(2)],
        //         ['2026', (22136272/19).toFixed(2)],
        //         ['2027', (41234746/19).toFixed(2)],
        //         ['2028', (64321658/19).toFixed(2)],
        //     ]
        // }
        ]}
  />
  )
}

export default RevenueChart;