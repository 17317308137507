import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { QueryClient , QueryClientProvider} from 'react-query';

const queryClient = new QueryClient();

function Main() {
    return (
        <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <App />
         </QueryClientProvider>
    </React.StrictMode>
    )
}


const rootElement = document.getElementById("root");
ReactDOM.render(<Main />, rootElement);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
