import React from "react";
import img1 from "../../assets/images/image 2.png";
import img2 from "../../assets/images/image 3.png";
import img3 from "../../assets/images/image 4.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import "../../assets/css/funeral.css";

function Hero() {
  return (
    <div class="funeral-hero-container">
      <div class="hero-funeral-column-1" >
        <div style={{paddingInlineStart:"15%"}}>
          <p style={{ color: "#295052" }}>FUNERAL COVER</p>
          <p className="hero-funeral-heading">
            Ensuring Your Loved Ones&#39; Final Journey: Funeral &amp; Repatriation Solutions from
            South Africa to Zimbabwe.
          </p>
          <p style={{ marginTop: "0px",fontSize:20 }}>
            Designed to meet the needs of Zimbabweans living in the diaspora. Up to R10 000
            cash pay-out from just R100 a month
        </p>
         {/* <div style={{ display: "flex", alignItems: "center", marginTop: 30 }}>
          <a href="https://id.tsigiro.com/">
            <button className="btn btn-secondary" >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                }}
              >
                <p style={{ margin: 0, fontSize:13 }}>Packages</p>
              </div>
            </button>
          </a>
        </div> */}
       </div>
      </div>
        <div className="hero-funeral-bg"></div>
    </div>
  );
}

export default Hero;
