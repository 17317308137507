import PropertiesIcon from "../assets/images/PropertiesIcon.svg";
import InvestmentsIcon from "../assets/images/InvestmentsIcon.svg";
import FuneralIcon from "../assets/images/FuneralIcon.svg";
import SalesPerson from "../assets/images/sales.svg";
import HelpSupport from "../assets/images/HelpSupport.svg";
import MediaPress from "../assets/images/MediaPress.svg";
import Invest5 from "../assets/images/investments5.svg";
import Invest6 from "../assets/images/investments6.svg";
import Invest7 from "../assets/images/investments7.svg";

export const ModulesLowerData = [
  {
    icon: Invest5,
    title: "Register",
    description:
      "Register the required information so that you may kick start your investment journey with us.",
    buttonTitle: "Start Investing",
  },
  {
    icon: Invest6,
    title: "Fund your account",
    description:
      "Transfer money from a linked account, wire transfer money and deposit a check or transfer investments.",
    buttonTitle: "Start Investing",
  },
  {
    icon: Invest7,
    title: "Make investment",
    description:
      "If you're seeking to either purchase a house for yourself or participate in a property development investment with others, we've got you covered. And if you cant seem to find what youre looking for, simply submit a request to us and well source for it on your behalf",
    buttonTitle: "Get Started",
  },
];
