import './assets/css/agrostrong.css';
import Navbar from './components/navbar/Navbar';
import './assets/css/nav.css';
import './assets/css/hero.css';
import './assets/css/hero-lower.css';
import './assets/css/thridComponent.css';
import './assets/css/investments.css';
import './assets/css/modules.css';
import './assets/css/portfoliocta.css';
import './assets/css/productsinfo.css';
import './assets/css/innertsigiro.css';
import './assets/css/footer.css';
import './assets/css/faq.css';
import Hero from './components/heroes/Hero';
import Modules from './components/productscta/Modules';
import './assets/css/gallery.css';
import GalleryCTA from './components/videocta/GalleryCTA';
import Portfoliocta from './components/portfoliocta/Portfoliocta';
import { ProductsInfo } from './components/productsinfo/ProductsInfo';
import InnerProducts from './components/innerproductscta/InnerProducts';
import FAQ from './components/faqs/FAQ';
import Footer from './components/footer/Footer';
import { Switch, Route, HashRouter, BrowserRouter } from "react-router-dom";
import FuneralCover from './pages/FuneralCover';
import AboutUs from './pages/About';
import Investments from './pages/Investments';
import Properties from './pages/Properties';
import IPO from './pages/IPO';
import Cantact from './pages/Cantact';
import Faqs from './pages/Faqs';
import Partners from './pages/Partners';
import Gallery from './pages/Gallery';
import GalleryExplore from './components/gallery/Gallery';
import TermsOfService from './pages/TermsOfUse';
import PrivacyPolicy from './pages/PrivacyPolicy';
import BioSection from './pages/BioSection';
import BioSectionHome from './pages/BioSectionHome';
import Invest from './pages/Invest';
import Agrostrong from './pages/Agrostrong';


const Home = () => {

    return <div>
        <Navbar />
        <Hero />
        <GalleryCTA />
        <Modules />
        <Portfoliocta />
        <ProductsInfo />
        <InnerProducts />
        <FAQ />
        <Footer />
    </div >
};


function App() {
    return (
        !window.location.origin.includes("tsigiro.com") ?
            <HashRouter>
                <Switch>
                    <Route path="/" exact component={Home} />
                    <Route path="/funeral-cover" exact component={FuneralCover} />
                    <Route path="/about-us" exact component={AboutUs} />
                    <Route path="/investments" exact component={Investments} />
                    <Route path="/properties" exact component={Properties} />
                    <Route path="/ipos" exact component={IPO} />
                    <Route path="/contact" exact component={Cantact} />
                    <Route path="/faqs" exact component={Faqs} />
                    <Route path="/partners" exact component={Partners} />
                    <Route path="/gallery" exact component={Gallery} />
                    <Route path="/bfcapital" exact component={Invest} />
                    <Route path="/gallery/explore" exact component={GalleryExplore} />
                    <Route path="/terms-of-service" exact component={TermsOfService} />
                    <Route path="/privacy-policy" exact component={PrivacyPolicy} />
                    <Route path="/board-of-advisors" exact component={BioSectionHome} />
                    <Route path="/board-of-advisors/explore" exact component={BioSection} />
                    <Route path="/agrostrong" exact component={Agrostrong} />
                </Switch>
            </HashRouter> :
            <BrowserRouter>
                <Switch>
                    <Route path="/" exact component={Home} />
                    <Route path="/funeral-cover" exact component={FuneralCover} />
                    <Route path="/about-us" exact component={AboutUs} />
                    <Route path="/investments" exact component={Investments} />
                    <Route path="/properties" exact component={Properties} />
                    <Route path="/ipos" exact component={IPO} />
                    <Route path="/contact" exact component={Cantact} />
                    <Route path="/faqs" exact component={Faqs} />
                    <Route path="/partners" exact component={Partners} />
                    <Route path="/gallery" exact component={Gallery} />
                    <Route path="/bfcapital" exact component={Invest} />
                    <Route path="/gallery/explore" exact component={GalleryExplore} />
                    <Route path="/terms-of-service" exact component={TermsOfService} />
                    <Route path="/privacy-policy" exact component={PrivacyPolicy} />
                    <Route path="/board-of-advisors" exact component={BioSectionHome} />
                    <Route path="/board-of-advisors/explore" exact component={BioSection} />
                    <Route path="/agrostrong" exact component={Agrostrong} />
                </Switch>
            </BrowserRouter>
    );
}

export default App;
