import React from "react";
import "../../assets/css/about.css";
import img1 from "../../assets/images/partner1.png";
import img2 from "../../assets/images/partner2.png";
import img3 from "../../assets/images/agrostrong.png";
import img4 from "../../assets/images/turnberry.png";
import img5 from "../../assets/images/partner4.png";
import img7 from "../../assets/images/dk.png";

function Third() {
  return (
    <div class="about-container-partners" >
      <div class="about-partners-left">
        <h1 className="hero-third-heading" style={{fontWeight:500}}>
          Our partners are essential to the success of our vision.
        </h1>
        <p>
        Our partnership structure enables
         us to collaborate with top tier
         service providers who share the 
        same vision of delivering quality 
        service and solutions to our clients.
        </p>
      </div>
      <div class="about-partners-right">
        <img src={img1} alt="partner-1" className="partner-lower" />
        <img src={img2} alt="partner-2" className="partner-lower" />
        <img src={img3} alt="partner-3" className="partner-lower" />        
        <img src={img4} alt="partner-4" className="partner-lower" />
        <img src={img5} alt="partner-5" className="partner-lower" />
        <img src={img7} alt="partner-6" className="partner-lower" />
      </div>
    </div>
  );
}

export default Third;
