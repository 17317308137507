import React from "react";
import "../../assets/css/about.css";
import img1 from "../../assets/images/aboutHero.png";

function Hero() {
  return (
    <div className="about-container">
      {/* <img src={img1} alt="about-hero" className="hero-image" /> */}
      <h1 style={{ color: "#295052", marginTop: "80px", fontWeight: 500 }}>
        About Us
      </h1>
      <div className="center-hero-div" style={{ fontSize: 18, fontWeight: 500 }}>
        <p>
          Tsigiro Usekelo (TU) platform exist to unlock the opportunities that the collaboration between
          Africans in diaspora and those back home. At the core of Tsigiro Usekelo (TU)
          is to provide a platform for the diaspora to invest and have access to housing
          back home, over, and above helping the diaspora live a financially meaning life
          in the countries that they are based. TU is anchored by Diaspora Kapita and partners.<br></br><br></br>
          Diaspora Kapita is an investment holding with investments in South Africa and Zimbabwe.
          The company has investments in financial services, agriculture, contract mining and building materials supply.
          <br></br><br></br>
          <span style={{ fontSize: 25 }}>Our vision is to help people have a better financial life.</span> <br></br><br></br>
          Tsigiro Usekelo – WE STAND WITH YOU.
        </p>
      </div>
    </div>
  );
}

export default Hero;
