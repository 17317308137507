import PropertiesIcon from '../assets/images/PropertiesIcon.svg';
import InvestmentsIcon from '../assets/images/InvestmentsIcon.svg';
import FuneralIcon from '../assets/images/FuneralIcon.svg';

export const Contact = [
    {
        icon:PropertiesIcon,
        title:'Sales',
        description:' Connect with us and our rep will contact you to arrange an initial discussion.',
        buttonTitle:'Contact Sales',
        email: 'sales@tsigiro.com',
        link:'/properties'
    },
    {
        icon:InvestmentsIcon,
        title:'Help & Support',
        description:'Talk to us about our selection of offerings to help you get started',
        buttonTitle:'Contact Suppport',
        email: 'info@tsigiro.com',
        link:'/investments'
    },
    {
        icon:FuneralIcon,
        title:'Media & Press',
        description:'Get in touch with us for all Media and Press Inquiries',
        buttonTitle:'Get Press kit',
        email: 'press@tsigiro.com',
        link:'/funeral-cover'
    }
]