import React from 'react';
import Logo from '../../assets/images/logo.png';
import {MenuItems, MobileMenuITems} from '../../constants/Menu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import {Link} from 'react-router-dom';

const Navbar = () => {

    const [selected, setSelected] = React.useState(0);

    const handleLogin = ()=>{
        window.location.href = process.env.REACT_APP_AUTH;
    };

        /* Open when someone clicks on the span element */
    function openNav() {
    document.getElementById("myNav").style.width = "100%";
    };

    /* Close when someone clicks on the "x" symbol inside the overlay */
    function closeNav() {
    document.getElementById("myNav").style.width = "0%";
    };

  return (
    <div className='nab-bar'>
      <Link to='/'>
        <img src={Logo} alt="tsigiro-img"/>
      </Link>
      <div className='nav-links'>
        <ul>
            {
                MenuItems.map((x)=>{
                        if(x.hasChildren){
                            return <div className="ltn__drop-menu user-menu">
                    <ul>
                      <li>
                              <div style={{display:'flex',justifyContent:'space-between',width:'220px',alignItems:'center'}}>
                                      <p style={{margin:0,marginRight:0}}>{x.title}</p> 
                                      <FontAwesomeIcon icon={faChevronDown} />
                              </div>
                              <ul className="go-top">
                              {
  x.children.map((x) => (
    <li style={{ color: "#000" }}>
      {x.link === "https://ahoyiafrica.com/" || x.link === "http://systech.tsigiro.com/" ? (
        <a href={x.link} style={{ textDecoration: "none", color: "inherit" }} target='_blank' rel="noopener noreferrer">
          <p className="hvr-link" style={{ margin: 0 }}>
            {x.title}
            <br />
            <span>{x.description}</span>
          </p>
        </a>
      ) : (
        <Link to={x.link} style={{ textDecoration: "none", color: "inherit" }}>
          <p className="hvr-link" style={{ margin: 0 }}>
            {x.title}
            <br />
            <span>{x.description}</span>
          </p>
        </Link>
      )}
    </li>
  ))
}

</ul>

                      </li>
                    </ul>
                  </div>
                        }else{
                        return <li style={{display:"flex",alignItems:'center'}}>
                                  <Link  to={x.link} style={{textDecoration:'none',color:'inherit',display:'flex',alignItems:"center",justifyContent:'center'}}>
                                       {x.title}
                                  </Link>
                          </li>
                        }
                })
            }
        </ul>
        <div className='nav-buttons'>
        <button 
        onClick={handleLogin}
        className='btn btn-secondary'> 
            Sign In
        </button>
      </div>
      </div>
         <div id="myNav" className="overlay">
        <a href="javascript:void(0)" class="closebtn" onClick={closeNav}>&times;</a>
        <div className="overlay-content">
        {
  MobileMenuITems.map((menu, index) => (
    <div className='single-selection' onClick={() => {
      setSelected(index);
      closeNav();
    }}>
      {menu.link === "https://ahoyiafrica.com/" || menu.link === "http://systech.tsigiro.com/" ?
        <a href={menu.link} target='blank' rel='noopener' style={{color: selected === index ? "#fff" : "#000", fontSize: selected === index ? 23 : 18, fontWeight: 400, margin: 5}}>
          {menu.title}
        </a>
      :
        <Link to={menu.link} style={{color: selected === index ? "#fff" : "#000", fontSize: selected === index ? 23 : 18, fontWeight: 400, margin: 5}}>
          {menu.title}
        </Link>
      }
      {selected === index && <hr style={{height: '1px', marginBottom: -3, backgroundColor: '#fff', color: '#fff'}}></hr>}
    </div>
  ))
}

        </div>
         <div className='nav-buttons' style={{marginTop:250}}>
                <button 
                style={{color:'#fff'}}
                onClick={handleLogin}
                className='btn btn-primary'> 
                    Sign In
                </button>
            </div>
      </div>
      <div className="mobile-menu-selector">
        <FontAwesomeIcon icon={faBars} size="2x" style={{marginRight:20}} onClick={openNav}/>
      </div>
    </div>
  )
}

export default Navbar