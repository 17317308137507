import { FullPage, Slide } from "react-full-page";
import "../assets/css/gallery.css";
import Navbar from "../components/navbar/Navbar";
import Footer from "../components/footer/Footer";
import InvestmentsModules from "../components/investmentscta/Modules";
import { InvestmentInfo } from "../components/productsinfo/Investments";
import InnerInvestments from "../components/innerproductscta/InnerInvestments";
import ModulesTwo from "../components/investmentscta/ModulesLower";
import BannerStart from "../components/heroes/BannerStart";
import HeroLower from "../components/hero-lower/Hero-lower";
import ThridComponent from "../components/investmentscta/ThridComponent";
import InvestmentsFaqs from "../components/faqs/InvestmentsFAQS";

const Investments = () => {
  return (
    <div>
        <Navbar />
        <BannerStart />
        <InvestmentsModules />
        <ThridComponent />
        {/* <InvestmentInfo /> */}
          {/* <InnerInvestments /> */}
          <ModulesTwo />
          {/* <HeroLower /> */}
        <InvestmentsFaqs />
        <Footer />
    </div>
  );
};

export default Investments;
