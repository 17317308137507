import React, { useState } from "react";
import "../../assets/css/faqs.css";
import { FAQS } from "../../constants/FAQS";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { Data } from "../../constants/LeftFaqData";
import { DataRight } from "../../constants/RightFaqData";
import { Link } from "react-router-dom";

function First() {
  const [selectedFaqs, setSelectedFaqs] = useState({});
  const [select, setSelect] = useState({});

  return (
    <div>
      <div className="faqs-container-first">
        <div>
          <h1 className="heading-faqs">FAQs</h1>
          <p className="faqs-first-paragraph">
            Here are some of the most common questions & answers that our
            existing and new customers ask.
          </p>
        </div>
      </div>

      <div className="faqs-section-container">
        <div className="faq-left-lower">
          {Data.map((x, index) => {
            return (
              <div key={index} className="faq-item-lower ">
                <div className="faq-selector">
                  <p className="title">{x.title}</p>
                  <FontAwesomeIcon
                    onClick={() => {
                      setSelect({
                        ...select,
                        [index]: select[index] ? !select[index] : true,
                      });
                    }}
                    icon={select[index] ? faMinus : faPlus}
                  />
                </div>
                <div
                  className={`faq-description ${
                    select[index] ? "faq-visibility" : "faq-non-visibility"
                  }`}
                >
                  <p>{x.description}</p>
                </div>
              </div>
            );
          })}
        </div>
        <div className="faq-right-lower">
          {DataRight.map((x, index) => {
            return (
              <div key={index} className="faq-item-lower ">
                <div className="faq-selector">
                  <p className="title">{x.title}</p>
                  <FontAwesomeIcon
                    onClick={() => {
                      setSelectedFaqs({
                        ...selectedFaqs,
                        [index]: selectedFaqs[index]
                          ? !selectedFaqs[index]
                          : true,
                      });
                    }}
                    icon={selectedFaqs[index] ? faMinus : faPlus}
                  />
                </div>
                <div
                  className={`faq-description ${
                    selectedFaqs[index]
                      ? "faq-visibility"
                      : "faq-non-visibility"
                  }`}
                >
                  <p>{x.description}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="faqs-container-first" style={{ paddingBottom: "80px" }}>
        <h1 className="faqs-upper-heading">Still have a question?</h1>
        <div style={{ width: "40%" }}>
          <p className="faqs-lower-paragraph">
            If your question wasn’t answered or not described in depth, feel
            free to contact our team for assistance.
          </p>
        </div>
        <Link to="/contact">
          <button className="button">
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              <p style={{ margin: 0 }}>Contact Us</p>
            </div>
          </button>
        </Link>
      </div>
    </div>
  );
}

export default First;
