import React,{useState} from 'react';
import {ProductsInformation} from '../../constants/ProductsInfo';

export const ProductsInfo = () => {

    const [selectedProduct,setSelectedProduct] = useState(0);
    const [selectedChild,setSelectedChild] = useState({
        0:0,
        1:0,
        2:0
    })

  return (
    <div className='products-info'>
        <div className='products-selector'>
            {
                ProductsInformation.map((x, index)=>{
                    return  <div 
                            onClick={()=>setSelectedProduct(index)}
                            className={selectedProduct === index && "selected-product"}>
                            <p >{x.module}</p>
                            </div>
                })
            }
        </div>
        <div className={`products-info-image ${ProductsInformation[selectedProduct].className}`}>
        </div>
         <div className='products-info-selected'>
            {
                ProductsInformation[selectedProduct].children.map((x, index)=>{
                    return <div 
                            onClick={()=>{
                                setSelectedChild({
                                    ...selectedChild,[selectedProduct]:index
                                })
                            }}
                            className={`products-info-child ${selectedChild[selectedProduct] === index && "selected-inner-child"}`}
                            >
                                <p className='title'>{x.title}</p>
                                <p>{x.description}</p>
                            </div>
                })
            }
        </div>
    </div>
  )
}
