export const FAQSINVESTMENTS = [
  {
    title: "What is a unit investment trust?",
    description:
      "Unit investment trusts (UITs) are registered investment companies with some characteristics of mutual funds and some of closed-end funds. Like mutual funds, UITs issue redeemable shares (called “units”). Like closed-end funds, however, UITs typically issue only a specific, fixed number of shares.In contrast to closed-end funds, a UIT does not actively trade its investment portfolio. Instead, it buys and holds a set of particular investments until a set termination date, at which time the trust is dissolved and proceeds are paid to shareholders.        ",
  },
  {
    title: "Are UIT holdings professionally selected and diversified?",
    description:
      "Yes. The securities in a UIT, which are listed in its prospectus, are professionally selected to meet a stated investment objective such as growth, income, or capital appreciation. UITs employ a “buy-and-hold” investment strategy; once the trust’s portfolio is selected, its securities typically are not traded. (Some UITs may sell or replace a security if questions arise concerning the financial viability of the issuer or the security’s creditworthiness.) Most UITs hold a diversified portfolio of securities. The extent of each UITs diversification will be described in its prospectus.        ",
  },
];
