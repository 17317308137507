export const FAQSFUNERAL = [
  {
    title: "How long does it take for a claim to be paid?",
    description:
      "Claims are usually processed within 24 - 72 hours of receiving all necessary documentation. Claims are usually processed within 48 - 72 hours of receiving all necessary documentation from policy holders or nominated beneficiaries.",
  },
  {
    title:
      "Who is eligible for Tsigiro | Usekelo funeral and repatriation cover?",
    description:
      "Zimbabwean foreign nationals below the  ,living in South Africa are eligible for Tsigiro  | Usekelo funeral and repatriation cover. Tsigiro Usekelo is designed to cover Foreign Nationals living in South Africa, mostly Zimbabwean nationals.",
  },
  {
    title:
      "What are the waiting periods for the Tsigiro |Usekelo funeral cover?",
    description:
      "You and other life assured's are covered immediately for accidental death after your first premium is paid. There is a waiting period of three (3) months from the benefit start date and we must receive three (3) benefit premiums in a row before a claim can be submitted in the case of death caused by natural causes. There is a waiting period of twelve (12) months from the benefit start date and we must receive twelve (12) benefit premiums in a row before a claim can be submitted in the case of death caused by suicide. ",
  },
  {
    title:
      "What happens if I don’t want 21st Century Life to conduct the funeral service for me?",
    description:
      "f 21st Century Life does not conduct the funeral service, then your total cover benefit will be paid to the claimant in cash.      ",
  },
];
