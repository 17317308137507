import React from "react";
import img1 from "../../assets/images/hero-1.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

function BannerStart() {
  return (
    <div class="funeral-hero-container">
      <div class="hero-funeral-column-1">
        <div style={{paddingInlineStart:"15%"}}>
           <p style={{ color: "#295052" }}>INVESTMENTS</p>
            <p className="title" style={{color: "#295052"}}>Investing made easy.</p>
            <p style={{ width: "80%",fontSize:20 }}>
            We provide a unified platform for all your investments, whether it&#39;s in Cattle,
            listed shares on ZSE/VFSE, property, vetted unlisted companies, or Unit Trusts. Our
            partnerships eliminate the need for you to search around for investment opportunities.
            </p>
            <div style={{ display: "flex", alignItems: "center", marginTop: 30 }}>
              <a href="https://id.tsigiro.com">
                <button className="btn btn-secondary">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      alignItems: "center",
                    }}
                  >
                    <p style={{ margin: 0 }}>Get Started</p>
                    {/* <FontAwesomeIcon icon={faChevronRight} /> */}
                  </div>
                </button>
              </a>
        </div>
        </div>
      </div>
      <div className="hero-investments-right">
        <div className="hero-investments-bg"></div>
      </div>
    </div>
  );
}

export default BannerStart;
