/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import { Link, useHistory } from "react-router-dom";
import "../assets/css/galleryImages.css";
import gala2 from "../assets/images/nafuico.jpg"
import gala3 from "../assets/images/nafuico2.jpg"
import gala4 from "../assets/images/nafuico3.jpg"
import gala5 from "../assets/images/nafuico4.jpg"
import gala6 from "../assets/images/nafuico5.jpg"
import gala7 from "../assets/images/nafuico6.jpg"
import gala8 from "../assets/images/nafuico7.jpg"
import gala9 from "../assets/images/nafuico8.jpg"
import gala10 from "../assets/images/nafuico9.jpg"
import gala11 from "../assets/images/nafuico10.jpg"
import galla12 from "../assets/images/nafuico11.jpg"
import galla13 from "../assets/images/nafuico12.jpg"
import cattle1 from "../assets/images/Cattle 2.jpeg"
import cattle2 from "../assets/images/Cattle 3.jpeg"
import cattle4 from "../assets/images/Cattle 4.jpeg"
import cattle5 from "../assets/images/Cattle 5.jpeg"
import cattle6 from "../assets/images/Cattle 6.jpeg"
import cattle7 from "../assets/images/Cattle 7.jpeg"
import dk from "../assets/images/51-min.jpg"
import dk1 from "../assets/images/52-min.jpg"
import dk2 from "../assets/images/53-min.jpg"
import dk3 from "../assets/images/54-min.jpg"
import dk4 from "../assets/images/55-min.jpg"
import dk5 from "../assets/images/57-min.jpg"
import dk6 from "../assets/images/58-min.jpg"
import dk7 from "../assets/images/59-min.jpg"
import dk8 from "../assets/images/60-min.jpg"
import dk9 from "../assets/images/61-min.jpg"
import dk13 from "../assets/images/65-min.jpg"
import dk14 from "../assets/images/66-min.jpg"
import Footer from "../components/footer/Footer";
import Navbar from "../components/navbar/Navbar";

const images = [
  {
    id: 1,
    eventType: "Dinner",
    date: "Novemeber 20, 2019",
    heading: "NAFUICO Investor Conference 2019.",
    description: "NAFUICO is an associate of Diaspora Kapita. NAFUICO is invested in funeral and financial services through 21 st Century Life. Within just 5 years of its inception, the company has achieved a valuation of more than R100 million.",
    imageUrl: gala6,
    images: [gala3, gala2, gala4, gala5, gala6, gala7, gala8, gala9, gala10, gala11, galla12, galla13],
    imageHeading: galla12,
  },
  {
    id: 2,
    eventType: "Exihibition.",
    date: "October 11, 2022",
    heading: "Cattle Investments.",
    description: "We partnered with MC Meats to manage our cattle investments. MC Meats offers a holistic solution to producing high quality protein by controlling the entire production process from farms, feedlots and abattoirs to wholesale and retail supply and even a large processing division in Harare.",
    imageUrl: cattle1,
    images: [cattle1, cattle2, cattle4, cattle5, cattle6, cattle7],
    imageHeading: cattle1,
  },
  {
    id: 3,
    eventType: "Meeting.",
    date: "January 5, 2023",
    heading: "DK 2022 Annual General Meeting.",
    description: "The board and management of Diaspora Kapita (DK) have been given the mandate by the shareholders to fulfil the original intent of allowing other diaspora to participate in the growth of DK. This will be achieved by opening DK to new investments through a Diaspora Fund, with the ultimate goal of listing DK in 5 years.",
    imageUrl: dk,
    images: [dk1, dk2, dk3, dk4, dk5, dk6, dk7, dk8, dk9, dk13, dk14],
    imageHeading: dk2,
  },
  
];

const Gallery = () => {

  // initialize useHistory hook
const history = useHistory();

// define function to handle redirect
const handleRedirect = (image) => {
  history.push({
    pathname: '/gallery/explore',
    state: { image }
  });
  handleExplore()
};

const handleExplore = () => {
  history.push('/gallery/explore');
};
  return (
    <>
      <Navbar />
      <div className="headingSectionInGallery">
        <h1 className="mainHeadingInGallery">EXPLORE TSIGIRO USEKELO</h1>
        <h1 className="subHeadingInGallery">Gallery</h1>
      </div>
      
      <div className="gallery-container">
        {images.map((image) => (
          <div className="cardInGallery" key={image.id}>
            <img src={image.imageUrl} alt="gallery-image" className="imageInGallery"/>
            <div className="card-details">
              <p className="event-type"><span style={{backgroundColor: "#FEFAF5", borderRadius: "5px"}}>{image.eventType}</span> <span className="event-date">{image.date}</span></p>
              <h2 className="headingInGallery">{image.heading}</h2>
              <p className="event-description">{image.description}</p>
                
                <p className="explore-button" onClick={() => handleRedirect(image)}>Explore<span className="icon-arrow"></span></p>
            </div>
          </div>
        ))}
      </div>
      <Footer />
    </>
  );
};

export default Gallery;
