export const ProductsInformation = [
  {
    module: "Property",
    className: "property-info-background",
    children: [
      {
        title: "Simplified funding process",
        description: "We provide financial plans to simplify the process of land acquisition, home construction, or purchasing completed houses for our members.",
      },
      {
        title: "Access to building materials",
        description:
          "By leveraging our network of suppliers, we ensure that members have access to quality building materials at competitive prices. ",
      },
      {
        title: "Construction services",
        description:
          "Providing access to reliable and experienced construction service providers to ensure their homes are built to high standards and in compliance with local regulations.",
      },
    ],
  },
  {
    module: "Investments",
    className: "investments-info-background",
    children: [
      {
        title: "Traditional & Reliable",
        description:
          "Invest in a time-tested, familiar asset that offers steady growth and supports local farmers.",
      },
      {
        title: "Invest Globally",
        description:
          "Enhance your financial security by adding a unique and tangible investment to your mix.",
      },
      {
        title: "Full Support and Security",
        description:
          "Enjoy a hands-off investment option with minimal upkeep, as Tsigiro takes care of the details for you..",
      },
    ],
  },
  {
    module: "Funeral Cover",
    className: "insurance-info-background",
    children: [
      {
        title: "Protect Loved Ones",
        description:
          "Tsigiro is designed to meet the needs of all Zimbabwean diaspora, you deserve to grieve your loved ones in a dignified and struggle free manner that is why we are offering to remove from your hands the burden of organizing the memorial service. ",
      },
      {
        title: "Affordable Costs",
        description:
          "We empathize with you in your difficult time and we do not wish to make the process of sending off your loved one more difficult through unreasonable charges, from as little as R120/ month you can get a Funeral and Repatriation Cover for yourself and your loved ones.          ",
      },
      {
        title: "Quality Services",
        description:
          "Good service is good business that is why our services offer uncompromised quality. 21st Century life will repatriate the deceased locally or from anywhere in Africa and the preparations are done in line with the Department of Health’s Regulations. ",
      },
    ],
  },
];

export const PropertiesInformation = [
  {
    module: "Property",
    className: "property-info-background",
    children: [
      {
        title: "Fractionalised Ownership",
        description:
          "We fractionalise property ownership by creating a company which owns and distributes shares among investors on Tsigiro Properties.",
      },
      {
        title: "Long term investing",
        description:
          "Property investments are considered medium to long-term and that is why we intend to hold properties for 5-7 years at a time.",
      },
      {
        title: "New Listings",
        description:
          "You can submit an application for as many shares as you like and when the IPO successfully closes your shares will be issued to you.",
      },
    ],
  },
];
