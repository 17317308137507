import React from 'react';
import Partners from '../../assets/images/partners.svg';
import {Products} from '../../constants/Products';
import Modules from '../productscta/Modules';

const GalleryCTA = () => {
  return (
    <div className='gallery-cta-container'>
        <p className='title'><span>Why<hr></hr></span> Tsigiro Usekelo ?</p>
        <p style={{fontSize:15}}>Owning property in Zimbabwe can be challenging - 
             with high deposit requirements and short pay-off periods, many cannot access the required funding - with statistics showing that only 2.5% of Zimbabweans have a mortgage. Introducing the Tsigiro Usekelo platform, which makes it easier for you to own a home,
             back home, by offering access to funding, building materials, and construction services in one place.  
             <br>
             </br>
             {/* Click the videos below for more acquintance with our vision */}
        </p>
        {/* <img src={Partners} alt="tsigiro-partners"/>  */}
        <iframe width="966" height="543" style={{marginBottom:20}}  src="https://www.youtube.com/embed/CfndWK_3TxU" title="Tsigiro Usekelo" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen>
        </iframe>
    </div>

  )
}

export default GalleryCTA
