import React from 'react'
import {Products} from '../../constants/Products'

const Modules = () => {
  return (
    <div className='modules-cta-container'>
        <div className='modules-title'>
        <p className='larger-text'>Get quick access to a <span className='inner-border'>better life<hr></hr></span></p>
        <p style={{margin:0}}>Make the rest of your life the best life, by choosing Tsigiro Usekelo you become a valuable  part of our ever growing community. <br></br> There is no better time to start than now!</p>    
        </div>
   
    <div className='modules-cta-section'>
        {
        Products.map((x,index)=>{
            return <div className='ribbon-cta-container'>
                        <div key={index} className="single-cta-module">
                            <img src={x.icon} alt={"tsigiro" + x.title}/>
                            <h3>{x.title}</h3>
                            <p style={{fontSize: "14px"}}>{x.description}</p>
                            <a href={x.link} style={{textDecoration:'none'}}>
                                 <p style={{margin:0,color:'#295052',fontSize:15,fontWeight:500}}>{x.buttonTitle +" >"} </p> 
                            </a>
                         
                    </div>
                    </div> 
        })
        }       
    </div>
    </div>

  )
}

export default Modules