import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import "../../assets/css/galleryExplore.css"
import Footer from '../footer/Footer';
import Navbar from '../navbar/Navbar';





const GalleryExplore = (props) => {
  const [state, setState] = useState({ image: {} });

  useEffect(() => {
    setState({ image: props.location.state.image });
  }, [props.location.state.image]);

  return (
    <>
    <Navbar />
    <div className="gallery-container-explore">
      <h1 className='gallery-heading-explore'><a href="/gallery" className='link-explore'  onClick={() => { document.location.reload() }}><span className="icon-arrow-explore"></span> Gallery</a></h1>
      <p><span className="workshop-label-explore">{state.image.eventType}</span>  <span className='padding-span-explore'>{state.image.date}</span></p>
      <h2 className="heading-section-explore">{state.image.heading}</h2>
      <img src={state.image.imageHeading} alt="placeholder" className="gallery-image-explore" />
      <p className="gallery-description-explore">{state.image.description}</p>
    </div>
    <div className="image-grid-explore">
      {state?.image?.images?.map((imageUrl, index) => (
        <img key={index} src={imageUrl} alt={`${index}`} />
      ))}
    </div>
    <Footer />
    </>
  );
}

export default GalleryExplore;
