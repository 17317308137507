import React from "react";
import img1 from "../../assets/images/Rectangle 5.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import "../../assets/css/hero-lower.css";

function HeroLower() {
  return (
    <div className="hero-container">
      <div className="hero-column-1 box">
        <span style={{ backgroundColor: "#FEFAF5" }}>
          <p style={{ color: "#D47721" }}>Investments</p>
        </span>
        <h1 className="hero-heading" style={{ fontSize: "38px" }}>
          New reality. <br /> A new way to invest
        </h1>
        <p className="hero-paragraph">
          Everyone yearns to one day own their dream home and building that home
          in one's native country is a cherry on top (or an added advantage),
          that is why our Tsigiro Usekelo platform is inspired by the need to capacitate
          all Zimbabwean diaspora to access property ownership with minimal
          challenges/obstacles.
        </p>
        <div
          style={{ display: "flex", alignItems: "center", marginTop: "5px" }}
        >
          <a href="https://id.tsigiro.com">
            <button className="btn btn-secondary" style={{ marginTop: "30px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                }}
              >
                <p style={{ margin: 0 }}>Start Investing</p>
                {/* <FontAwesomeIcon icon={faChevronRight} /> */}
              </div>
            </button>
          </a>
        </div>
      </div>
      <div className="hero-column-2 box">
        <div className="hero-lower-img"></div>
      </div>
    </div>
  );
}

export default HeroLower;
