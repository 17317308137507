import React from "react";
import "../../assets/css/normalize.css";
import "../../assets/css/webflow.css";
import "../../assets/css/tsigiro.webflow.css";
import "../../assets/css/properties.css";
import CompanyHeroIcon from "../../assets/images/hero-image-tsigiro-comp.png";
import {useState} from "react";

import {PropertiesInformation} from "../../constants/ProductsInfo";

import PropertySelectionIcon from "../../assets/images/PropertySelectionIcon.svg";
import BuyAsSharesIcon from "../../assets/images/BuyAsShares.svg";
import CapitalGainsIcon from "../../assets/images/CapitalGains.svg";
import RentalDividendsIcon from "../../assets/images/RentalDividends.svg";

import FractionalInvestmentsPic from "../../assets/images/FractionalInvestmentsPic.svg";
import IPOpic from "../../assets/images/IPO.svg";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight} from "@fortawesome/free-solid-svg-icons";
import img1 from "../../assets/images/10.jpg";

const Value = () => {
    return (
        <div class="section_value" style={{backgroundColor: '#FBFDFD'}}>
            <div class="padding-global">
                <div class="container-large">
                    <div class="padding-section-medium">
                        <div class="title-block text-align-center">
                            <h2 class="heading-style-h3" style={{fontWeight: 500}}>
                                From Dream to Reality - Our Seamless Process to Property Acquisition for Zimbabweans Abroad
                            </h2>
                        </div>
                        <div class="padding-vertical padding-medium"></div>
                        <div class="value_grid">
                            <div
                                id="w-node-_2cff9749-870a-a1f0-f6d1-88dc30e22ed3-1871d271"
                                class="value_content"
                            >
                                <img src={PropertySelectionIcon}></img>
                                <h3 class="heading-style-h4" style={{fontWeight: 500}}>Become a Member </h3>
                                <p>Being a member of the Tsigiro Usekelo platform is the first step in your home ownership journey.</p>
                            </div>
                            <div
                                id="w-node-d8ac1b9b-dccc-ba97-efde-ab13477a6180-1871d271"
                                class="value_content"
                            >
                                <img src={BuyAsSharesIcon} style={{fontWeight: 500}}></img>
                                <h3 class="heading-style-h4" style={{fontWeight: 500}}>Property Selection</h3>
                                <p>Choose vacant land or ready-built homes in your preferred location</p>
                            </div>
                            <div
                                id="w-node-d39e0aa9-0e6f-7287-601b-0a45bcf730da-1871d271"
                                class="value_content"
                            >
                                <img src={CapitalGainsIcon} ></img>
                                <h3 class="heading-style-h4" style={{fontWeight: 500}}>Capital Raising</h3>
                                <p>whether you want a mortgage or direct loan from Tsigiro Usekelo, we will assist you to raise the capital required.</p>
                            </div>
                            <div
                                id="w-node-_3b883b52-aa93-376b-c445-2b2b5c27b335-1871d271"
                                class="value_content"
                            >
                                <img src={RentalDividendsIcon}></img>
                                <h3 class="heading-style-h4" style={{fontWeight: 500}} >Construction Begins</h3>
                                <p> from sourcing of the building materials to breaking ground and building your home.</p>
                            </div>
                        </div>
                        <div class="padding-vertical padding-medium"></div>
                        <div class="how-it-works_button-group">
                            <a href="https://id.tsigiro.com">
                                <button className="btn btn-secondary">
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-around",
                                            alignItems: "center",
                                        }}
                                    >
                                        <p style={{margin: 0}}>Get Started</p>
                                        {/* <FontAwesomeIcon icon={faChevronRight} /> */}
                                    </div>
                                </button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const HowItWorks = () => {
    return (
        <div class="section_how-it-works">
            <div class="padding-global">
                <div class="container-large">
                    <div class="padding-section-medium">
                        <div class="title-block text-align-center">
                            <div class="text-overline">HOW IT WORKS</div>
                            <h2 class="heading-style-h3" style={{fontWeight: 500}}>
                                Property investment in just a few clicks.
                            </h2>
                            <p>
                                To purchase, make a bid for the amount you wish to invest and
                                the highest price you&#x27;re willing to pay. Alternatively,
                                offer all or portion of your shares for sale and specify the
                                lowest price you&#x27;re willing to accept. That’s property
                                investment in just a few clicks.
                            </p>
                        </div>
                        <div class="padding-vertical padding-medium"></div>
                        <div class="how-it-works_grid">
                            <div
                                id="w-node-bea87fe2-7c99-68c6-4406-a180ec4f36e9-1871d271"
                                class="how-it-works_content"
                            >
                                <div class="heading-style-h4" style={{fontWeight: 500}}>Create account</div>
                                <p>
                                    Create Tsigiro Usekelo Investment account. It&#x27;s free and has no
                                    minimums.
                                </p>
                            </div>
                            <div
                                id="w-node-_1815eceb-e529-8aef-94b7-4cfe84ea48f4-1871d271"
                                class="how-it-works_content"
                            >
                                <div class="heading-style-h4" style={{fontWeight: 500}}>Browse listings</div>
                                <p>
                                    Go through the properties available in new listings and on the
                                    market.
                                </p>
                            </div>
                            <div
                                id="w-node-_0eeeb3d1-4408-d66d-f214-70ae723741a0-1871d271"
                                class="how-it-works_content"
                            >
                                <div class="heading-style-h4" style={{fontWeight: 500}}>Buy shares</div>
                                <p>
                                    Participate in the most recent IPOs or purchase shares at
                                    auction.
                                </p>
                            </div>
                        </div>
                        <div class="padding-vertical padding-medium"></div>
                        <div class="how-it-works_button-group">
                            <a href="https://id.tsigiro.com">
                                <button className="btn btn-secondary">
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-around",
                                            alignItems: "center",
                                        }}
                                    >
                                        <p style={{margin: 0}}>Get Started</p>
                                        {/* <FontAwesomeIcon icon={faChevronRight} /> */}
                                    </div>
                                </button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export const IPO = () => {
    return (
        <div class="section_ipo">
            <div class="padding-global">
                <div class="container-large">
                    <div class="padding-section-medium">
                        <div class="title-block_full">
                            <div class="title-block_content">
                                <div class="text-overline">Past Property IPOs</div>
                                <h2 class="heading-style-h2" style={{fontWeight: 500}}>Our IPO track record</h2>
                                <p class="text-size-medium">
                                    Take a peek at our latest IPOs, they will give you insight on
                                    how successful the past IPO were and how investors ...
                                </p>
                            </div>
                            <Link to={"/ipos"} class="button is-secondary w-button">
                                View All
                            </Link>
                        </div>
                        <div class="padding-vertical padding-medium"></div>
                        <div class="ipo_grid">
                            <div
                                id="w-node-cdc58fc2-c70f-0be7-419f-00f619f281d2-1871d271"
                                class="ipo_content"
                            >
                                <div class="ipo_image-wrapper">
                                    <img
                                        src="images/joina.png"
                                        loading="lazy"
                                        sizes="(max-width: 479px) 100vw, (max-width: 767px) 95vw, (max-width: 991px) 43vw, 29vw"
                                        width="416"
                                        srcset="images/joina.png 500w"
                                        alt=""
                                        class="ipo_image"
                                    />
                                </div>
                                <div class="ipo_info">
                                    <img src={IPOpic}></img>
                                    <div class="ipo_location">Harare</div>
                                    <div class="heading-style-h4" style={{fontWeight: 500}}>Joina City</div>
                                    <p>
                                        Joina City is the 3rd tallest building in Zimbabwe standing
                                        at 105 metres.
                                    </p>
                                    <div class="padding-vertical padding-small"></div>
                                    <div class="ipo_meta">
                                        <div
                                            id="w-node-fb2c0446-a2c8-5886-9e96-9e0d9447e73b-1871d271"
                                            class="ipo_logo"
                                        >
                                            <img
                                                src="images/Image_1.png"
                                                loading="lazy"
                                                width="48"
                                                alt=""
                                                class="image"
                                            />
                                        </div>
                                        <div
                                            id="w-node-_0b15619b-4bc5-30f2-b44e-bec71858cfba-1871d271"
                                            class="ipo_values"
                                        >
                                            <div class="ipo_money-raised">$898 999 878</div>
                                            <div class="ipo_investors">
                                                <div class="text-size-small">
                                                    <span class="value-span">100% </span>Oversubscribed
                                                </div>
                                                <div class="ipo_seperator"></div>
                                                <div class="text-size-small">
                                                    <span class="value-span">8787 </span>Investors
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                id="w-node-_23bd9d02-b1e8-ff6f-a5cc-b07db1726e64-1871d271"
                                class="ipo_content"
                            >
                                <div class="ipo_image-wrapper">
                                    <img
                                        src="images/joina.png"
                                        loading="lazy"
                                        sizes="(max-width: 479px) 100vw, (max-width: 767px) 95vw, (max-width: 991px) 43vw, 29vw"
                                        width="416"
                                        srcset="images/joina.png 500w"
                                        alt=""
                                        class="ipo_image"
                                    />
                                </div>
                                <div class="ipo_info">
                                    <img src={IPOpic}></img>
                                    <div class="ipo_location">Harare</div>
                                    <div class="heading-style-h4" style={{fontWeight: 500}}>Joina City</div>
                                    <p>
                                        Joina City is the 3rd tallest building in Zimbabwe standing
                                        at 105 metres.
                                    </p>
                                    <div class="padding-vertical padding-small"></div>
                                    <div class="ipo_meta">
                                        <div
                                            id="w-node-_23bd9d02-b1e8-ff6f-a5cc-b07db1726e70-1871d271"
                                            class="ipo_logo"
                                        >
                                            <img
                                                src="images/Image_1.png"
                                                loading="lazy"
                                                width="48"
                                                alt=""
                                                class="image"
                                            />
                                        </div>
                                        <div
                                            id="w-node-_23bd9d02-b1e8-ff6f-a5cc-b07db1726e72-1871d271"
                                            class="ipo_values"
                                        >
                                            <div class="ipo_money-raised">$898 999 878</div>
                                            <div class="ipo_investors">
                                                <div class="text-size-small">
                                                    <span class="value-span">100% </span>Oversubscribed
                                                </div>
                                                <div class="ipo_seperator"></div>
                                                <div class="text-size-small">
                                                    <span class="value-span">8787 </span>Investors
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                id="w-node-_851a948d-0931-f578-0c62-e63f521f8cf6-1871d271"
                                class="ipo_content"
                            >
                                <div class="ipo_image-wrapper">
                                    <img
                                        src="images/joina.png"
                                        loading="lazy"
                                        sizes="(max-width: 479px) 100vw, (max-width: 767px) 95vw, (max-width: 991px) 43vw, 29vw"
                                        width="416"
                                        srcset="images/joina.png 500w"
                                        alt=""
                                        class="ipo_image"
                                    />
                                </div>
                                <div class="ipo_info">
                                    <img src={IPOpic}></img>
                                    <div class="ipo_location">Harare</div>
                                    <div class="heading-style-h4" style={{fontWeight: 500}}>Joina City</div>
                                    <p>
                                        Joina City is the 3rd tallest building in Zimbabwe standing
                                        at 105 metres.
                                    </p>
                                    <div class="padding-vertical padding-small"></div>
                                    <div class="ipo_meta">
                                        <div
                                            id="w-node-_851a948d-0931-f578-0c62-e63f521f8d02-1871d271"
                                            class="ipo_logo"
                                        >
                                            <img
                                                src="images/Image_1.png"
                                                loading="lazy"
                                                width="48"
                                                alt=""
                                                class="image"
                                            />
                                        </div>
                                        <div
                                            id="w-node-_851a948d-0931-f578-0c62-e63f521f8d04-1871d271"
                                            class="ipo_values"
                                        >
                                            <div class="ipo_money-raised">$898 999 878</div>
                                            <div class="ipo_investors">
                                                <div class="text-size-small">
                                                    <span class="value-span">100% </span>Oversubscribed
                                                </div>
                                                <div class="ipo_seperator"></div>
                                                <div class="text-size-small">
                                                    <span class="value-span">8787 </span>Investors
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const ProductsInfo = () => {
    const [selectedProduct, setSelectedProduct] = useState(0);
    const [selectedChild, setSelectedChild] = useState({
        0: 0,
        1: 0,
        2: 0,
    });

    return (
        <div className="products-info">
            <div
                className={`products-info-image ${PropertiesInformation[selectedProduct].className}`}
            >
                <img src={FractionalInvestmentsPic}></img>
            </div>
            <div className="products-info-selected">
                {PropertiesInformation[selectedProduct].children.map((x, index) => {
                    return (
                        <div
                            onClick={() => {
                                setSelectedChild({
                                    ...selectedChild,
                                    [selectedProduct]: index,
                                });
                            }}
                            className={`products-info-child ${selectedChild[selectedProduct] === index &&
                                "selected-inner-child"
                                }`}
                        >
                            <p className="title">{x.title}</p>
                            <p>{x.description}</p>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
const Hero = () => {
    return (
        <section class="section_hero section_hero_properties">
            <div class="padding-global">
                <div class="container-large">
                    <div class="padding-vertical padding-medium"></div>
                    <div class="padding-vertical padding-medium"></div>
                    <div class="hero_grid">
                        <div class="hero_content">
                            <div style={{paddingInlineStart: "15%"}}>
                                <div class="text-overline">Property Investments</div>
                                <h1 class="heading-style-h2" style={{fontWeight: 500}} >
                                    Helping You Own a Home, Back Home
                                </h1>
                                <p class="text-size-medium" style={{fontSize: "14px"}}>
                                    Breaking Down Barriers: Tsigiro Usekelo Makes Home Ownership Accessible and Empowers Zimbabweans Abroad to Invest in Zimbabwean Property.
                                </p>
                                <p class="text-size-medium" style={{fontSize: "14px"}}>
                                Choosing the right investment platform is part of wise investment that is why our Tsigiro Usekelo Trust Fund is offering you the best. Our platform is designed to let your money work for you by providing you with access to smart and easy investment where everything involving investment is at your convenience.
                                </p>
                                <ul>
                                   <li>
                                   Reduced stand/house deposit.
                                   </li> 
                                   <li>
                                   Prolonged repayment period.
                                   </li> 
                                   <li>
                                   Access to 21st Century Life Funeral Cover (no waiting period if client is switching from another service provider).
                                   </li> 
                                   <li>
                                   Access to Life Cover (in the event of death, the property is 100% owned by the beneficiaries).
                                   </li> 
                                </ul>
                                <div
                                    style={{display: "flex", alignItems: "center", marginTop: 30}}
                                >
                                    <a href="https://id.tsigiro.com">
                                        <button className="btn btn-secondary">
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-around",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <p style={{margin: 0}}>Get Started</p>
                                                {/* <FontAwesomeIcon icon={faChevronRight} /> */}
                                            </div>
                                        </button>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div
                            id="w-node-_3157dde6-7d6c-2c6c-6f71-3c89cddfa280-1871d271"
                            className="hero_image-wrapper"
                        >
                            {/* <img
                src={img1}
                loading="lazy"
                width="688.5"
                sizes="(max-width: 479px) 100vw, (max-width: 767px) 95vw, (max-width: 991px) 92vw, 40vw"
                alt="properties-hero"
                class="hero_image"
              /> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
const Modules = () => {
    return (
        <div class="page-wrapper">
            <div class="global-styles w-embed"></div>
            <main class="main-wrapper">
                <Hero />
                <Value />
                {/* <ProductsInfo /> */}
                {/* <IPO /> */}
                <HowItWorks />
            </main>
        </div>
    );
};

export default Modules;
