import React from "react";
import img1 from "../../assets/images/Rectangle 5.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

function Fourth() {
  return (
    <div class="about-container-fourth" style={{ backgroundColor: "#FFFFFF" }}>
      <div class="about-column-1 box">
        <img src={img1} alt="partner-1" className="fourth-image" />
      </div>
      <div class="about-column-3 box">
        <h1 className="fourth-heading-upper">
          <span style={{ backgroundColor: "#FEFAF5" }}>Investments</span>
        </h1>
        <h1 className="fourth-heading-lower" style={{fontWeight:'500'}}>
          New reality. A new way to invest.
        </h1>
        <p className="paragraph-fourth">
          Everyone yearns to one day own their dream home and building that home
          in one's native country is a cherry on top (or an added advantage),
          that is why our Tsigiro Usekelo platform is inspired by the need to capacitate
          all Zimbabwean diaspora to access property ownership with minimal
          challenges/obstacles.
        </p>
        <a href="https://id.tsigiro.com" target="_blank">
          <button className="btn btn-secondary" style={{ marginTop: "30px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              <p style={{ margin: 0 }}>Start Investing</p>
              {/* <FontAwesomeIcon icon={faChevronRight} /> */}
            </div>
          </button>
        </a>
      </div>
    </div>
  );
}

export default Fourth;
