import React, {useEffect, useState} from 'react'
import {TsigiroProducts} from '../../constants/TsigiroProducts';
import InnerTemplate from './InnerTemplate';

const InnerProducts = () => {
    const [selected,setSelected] = useState(0);

   useEffect(()=>{
    const changeHero = setInterval(()=>{
        if(selected >= 0 && selected < 1 ){
            setSelected(selected + 1)
        }else{
            setSelected(0)
        };

    },7000)

    return ()=>{
        clearInterval(changeHero);
    };
  },[selected])

  return (
        <InnerTemplate {...TsigiroProducts[selected]} />
  )
}

export default InnerProducts