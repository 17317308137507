import React from "react";
import { InvestmentsData } from "../../constants/Investments";
import "../../assets/css/investments.css";

const InvestmentsModules = () => {
  return (
    <div className="modules-cta-container">
      <p className="investments-heading">Cheap as chips and easy as pie</p>
      <div className="modules-cta-section">
        {InvestmentsData.map((x, index) => {
          return (
            <div className="ribbon-cta-container">
              <div key={index} className="single-cta-module">
                <img src={x.icon} alt={"tsigiro" + x.title} />
                <p className="title">{x.title}</p>
                <p style={{fontSize: "13px"}}>{x.description}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default InvestmentsModules;
