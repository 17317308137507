import React from "react";
import {FullPage, Slide} from "react-full-page";
import Navbar from "../components/navbar/Navbar";
import Footer from "../components/footer/Footer";
import Hero from "../components/funeral-cover/Hero";
import Second from "../components/funeral-cover/Second";
import Third from "../components/funeral-cover/Third";
import FuneralFaqs from "../components/faqs/FuneralFAQS";
import FuneralModules from "../components/funeral-cover/FuneralModules";

function FuneralCover() {
    return (
        <div>
            <Navbar />
            <Hero />
            <FuneralModules />
            <Third />
            {/* <Fifth /> */}
            <FuneralFaqs />
            <Footer />
        </div>
    );
}

export default FuneralCover;
