import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import img1 from "../../assets/images/front-view-man-doing-presentation-during-meeting 1.png";
import img2 from "../../assets/images/investments8.svg";
import img3 from "../../assets/images/investments10.svg";
import "../../assets/css/thridComponent.css";

function ThridComponent() {
  return (
    <div className="main-div" style={{paddingBottom:20}}>
      <h1
        style={{ color: "#D47721", fontSize: "14px", marginTop: "80px",fontWeight:500 }}
        className="third-heading"
      >
        <span
          style={{ backgroundColor: "#FEFAF5", borderRadius: "5px" }}
          className="span-third"
        >
          Start your investment journey
        </span>
      </h1>

      <h1 className="heading-third-component" style={{ color: "#295052",textAlign:'center',fontWeight:500,fontSize:38 }}>
        Start, or continue, your <br />
        investment journey!
      </h1>
      <div style={{width:'50%'}} >
        <p style={{ textAlign: "center" }}>
          Anything delayed is denied so why deny yourself such a life changing
          opportunity? Change can only take place immediately so there is no
          better time to start than now!
        </p>
      </div>
      <div className="main-bottom">
        <div className="lowerDiv">
          <img src={img2} alt="svg" className="img-third" />
          <h1
            style={{ textAlign: "center", color: "#295052",fontWeight:500,fontSize:38 }}
          >
            Value Increase
          </h1>
          <p style={{ textAlign: "center" }}>
          Allow your investments time to flourish and maintain a diversified portfolio.
          </p>
        </div>
        <div>
          <img src={img1} alt="placeholder" className="middle-image" />
        </div>
        <div className="lowerDiv">
          <img src={img3} alt="svg" className="img-third" />
          <h1
            style={{ textAlign: "center", color: "#295052", fontWeight:500,fontSize:38 }}
          >
            Dividend Rewards
          </h1>
          <p style={{ textAlign: "center" }}>
          Invest in assets that can generate consistent income over time.
          </p>
        </div>
      </div>
      <div style={{ display: "flex", alignItems: "center",marginTop:20 }}>
        <a href="https://id.tsigiro.com/">
          <button className="btn btn-secondary">
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                fontSize:12,
              }}
            >
              <p style={{margin: 0}}>Get Started</p>
              {/* <FontAwesomeIcon icon={faChevronRight} /> */}
            </div>
          </button>
          <br/>
          <br/>
        </a>
      </div>
    </div>
  );
}

export default ThridComponent;
