import React from 'react'
import '../../assets/css/contact.css';

const Modules = () => {
  return (
    <div className='modules-cta-container comm-card'>
        <h3 className='larger-text'>Queries</h3>
        <div className='modules-cta-section' >
            For general queries, including partnership opportunities, 
            please email hello@tsigiro.com    
        </div>
    </div>

  )
}

const Queries = () => {
  return (
    <div className='modules-cta-container queries-card'>
        <h3 className='larger-text' style={{marginTop : "1rem",fontWeight:500}}>Queries</h3>
        <div className='modules-cta-section' style={{textAlign : "center", padding : "2rem", marginTop : "-1rem"}} >
            For general queries, including partnership opportunities, <br/>
            please email info@tsigiro.com    
        </div>
    </div>

  )
}

export default Queries