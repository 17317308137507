import React from 'react';
import Footer from '../components/footer/Footer';
import Navbar from '../components/navbar/Navbar';
import "../assets/css/terms-of-use.css"

const PrivacyPolicy = () => {
  return (
    <>
    <Navbar />
    <div className="main-terms-of-use">
      <h3 style={{color: "#295052", textAlign: "center"}}>Privacy Policy</h3>
      <p>Tsigiro Usekelo is committed to ensuring the privacy and integrity of information
        received for purposes related to its functions or activities. All information of a personal
        nature submitted or provided by a person to whom the personal information relates will
        be treated as confidential and will not be disclosed to any person without the express
        consent of that person. The collection of personal information shall be for business
        purposes.</p>
      <p>Tsigiro Usekelo will take, implement, and maintain all such technical and organizational
        security procedures and reasonable measures to preserve the security and
        confidentiality of the personal information in its possession and to protect such
        personal information against unauthorized or unlawful disclosure, access or
        processing, accidental loss, destruction, or damage.</p> 
      
      <p>Tsigiro Usekelo may provide third parties with general information that does not include
        personal information about the person to whom the personal information relates, such
        as their contact numbers, email address, names etc. and which is intended to be used
        solely for compiling statistical or demographic information.</p>
      <p>This Privacy Policy will be governed by, and construed and interpreted in accordance
        with, the laws of the Republic of South Africa and Zimbabwe. When one provides
        Tsigiro Usekelo with their personal information, the person will be agreeing to the
        lawful processing of their personal information in line with applicable legislation.
        Stakeholders are kindly be advised to read and understand this Privacy Policy before
        they provide Tsigiro Usekelo with their personal information.</p>  

      </div>
      <Footer />
      </>
      )  
}

export default PrivacyPolicy;