import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import {redirectAuth} from '../../Utils/Redirect';

const Portfoliocta = () => {
  return (
        <div className='portfoliocta'>
            <p className='title'>Build a <span>diversified<hr></hr></span> investment <br></br>porfolio in minutes</p>
            <p style={{width:'50%'}}>Access a diverse investment portfolio through the Tsigiro Usekelo platform, offering a blend of property, cattle-backed investments, trust funds, and funeral services, all conveniently available in one place.</p>
             <div style={{display:'flex',alignItems:'center',marginTop:30,marginBottom:20}}>
                <button 
                onClick={redirectAuth}
                className='btn btn-secondary' style={{marginRight:30,}}> 
                    <span style={{display:'flex',justifyContent:'space-around'}}>
                        <p style={{margin:0}}>Register Now</p> 
                        {/* <FontAwesomeIcon icon={faChevronRight} /> */}
                    </span>
                        
                </button>
                {/* <a href='/investments' style={{fontSize:13,fontWeight:600,color:'#475569',cursor:'pointer',textDecoration:'none'}}>Learn More</a> */}
            </div>
            <div className='portfolia-image-a'>
            </div>
            <div className='portfolia-image-b'>
            </div>
             <div className='portfolia-image-c'>
            </div>
            <div className='portfolia-image-d'>
            </div>
        </div>
          )
};

export default Portfoliocta