import React from "react";
import img1 from "../../assets/images/video.png";
import "../../assets/css/funeral.css";
import {redirectAuth} from "../../Utils/Redirect";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight} from "@fortawesome/free-solid-svg-icons";

function Third() {
  return (
    <div>
      <div className="inner-tsigiro-funeral">
        <div className="funeral-trust-fund">
        </div>
        <div className='inner-tsigiro-benefits' style={{marginBottom: "100px"}}>
             <p className='title' >Funeral and Repatriation Cover is crafted to best suit your needs.</p>
            <div>
            <ul style={{ fontSize: "14px",marginTop:10}} className="custom-list">
            <li>
                  The Policy has 6 months waiting period for death due to natural causes.
                </li>
                <br />
                <li>
                  Repatriation Benefits apply to main member spouse and all dependents on main policy.
                </li>
                <br />
          </ul>
            </div>
        
           <div style={{display:'flex',alignItems:'center',marginTop:30}}>
                <button 
                onClick={redirectAuth}
                
                className='btn btn-secondary or-hover' style={{marginRight:30,width:'auto'}}> 
                    <span style={{display:'flex',alignItems:'center'}}>
                        <p style={{margin:0,marginRight:10}}>Become a member</p> 
                        {/* <FontAwesomeIcon icon={faChevronRight} /> */}
                    </span>
                </button>
            </div>
        </div>
      </div>
    </div>
  );
}

export default Third;
