import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import {redirectAuth} from '../../Utils/Redirect';
import {Link} from 'react-router-dom';

const HeroTemplate = (props) => {
  return (
    <div className={`hero-template ${props.backgroundClass}`}>
        <div className='hero-content-left'> 
            <div className='hero-text-main'>
                {props.title}
                {props.titleDescription}
            </div>
            <div style={{display:'flex',alignItems:'center',marginTop:30}}>
                <a 
                href="https://id.tsigiro.com"
                target='_blank'
                >
                    <button 
                    className='btn btn-secondary' style={{marginRight:30,
                    backgroundColor:'#E3944B'
                    }}> 
                        <span>
                            <p style={{margin:0,color:'#fff',fontSize:15}}>Invest Now</p> 
                        </span>
                    </button>
                </a>
            </div>
        </div>
    </div>
  )
}

export default HeroTemplate