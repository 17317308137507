import React from "react";
import { FullPage, Slide } from "react-full-page";
import Navbar from "../components/navbar/Navbar";
import Footer from "../components/footer/Footer";
import Hero from "../components/about/Hero";
import Second from "../components/about/Second";
import Third from "../components/about/Third";
import Fourth from "../components/about/Fourth";

function AboutUs() {
  return (
    <div>
        <Navbar />
        <Hero />
        <Second />
        <Third />
        <Fourth />
        <Footer />
    </div>
  );
}

export default AboutUs;
