import { FullPage, Slide } from "react-full-page";
import "../assets/css/gallery.css";
import Navbar from "../components/navbar/Navbar";
import Footer from "../components/footer/Footer";
import Index from "../components/properties/";
import PropFaqs from "../components/faqs/PropertyFAQS";

function Properties() {
  return (
    <div>
      <Navbar />
      {/* <Slide> */}
        <Index />
      {/* </Slide> */}
      {/* <Slide> */}
        <PropFaqs />
      {/* </Slide> */}
      {/* <Slide> */}
        <Footer />
      {/* </Slide> */}
    </div>
  );
}

export default Properties;
