import React from "react";
import { Contact } from "../../constants/ContactUs";

const Modules = () => {
  return (
    <div className="contct-modules-cta-container contact-channels" style={{ marginTop: 35 }}>
      <p className="larger-text">Contact Us</p>
      <p style={{ margin: 0, textAlign: "center" }}>
        Connect with us to talk about your needs and see how our team can help.
      </p>
      <div className="modules-cta-section">
        {Contact.map((x, index) => {
          return (
            <div className="ribbon-cta-container contact-channels-items">
              <div key={index} className="single-cta-module">
                <img src={x.icon} alt={"tsigiro" + x.title} />
                <h3 style={{ fontWeight: 500 }}>{x.title}</h3>
                <p>{x.description}</p>
                <a href={`mailto:${x.email}`}>
                  <button className="btn btn-primary">
                    <p className="hovered-link" style={{ margin: 0 }}>
                      {x.buttonTitle}
                    </p>
                  </button>
                </a>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Modules;
