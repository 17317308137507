import { FullPage, Slide } from "react-full-page";
import Contacts from "../components/contacts/Contacts";
import Queries from "../components/contacts/Queries";
import Locations from "../components/contacts/Locations";
import "../assets/css/gallery.css";
import Navbar from "../components/navbar/Navbar";
import Footer from "../components/footer/Footer";
import Modules from "../components/productscta/Modules";
import BranchLocations from "../components/contacts/Branches";

function Contact() {
  return (
    <div>
      <Navbar />
      <Contacts />
      <Queries />
      <BranchLocations />
      <Footer />
    </div>
  );
}

export default Contact;
