import React from 'react';
import { Tree, TreeNode } from 'react-organizational-chart';
import Nafuico from '../../assets/images/nafuico.png';
import Aubus from '../../assets/images/aubspro.png';
import TwentyFirst from '../../assets/images/212st.png';
import Linar from '../../assets/images/linar.png';

const NauficoStructure = () => {
  return (
    <Tree
            lineHeight='30px'
            lineWidth='2px'
            lineColor={'#e3944a'}
            label={
                <div style={{display:'flex',justifyContent:'center',flexDirection:'row'}}>
                    <div style={{padding:10,border:'2px solid #e3944a',borderRadius:10}}>
                        National Funeral Undertakers Investment Company <br/>
                        <img src={Nafuico} style={{ height: 40 }} />
                    </div>
                </div>
            }>
                {/* AUBS PRo */}
        <TreeNode label={<div>
            <strong>100%</strong>
        </div>}>
            <TreeNode label={<div>
                <img src={Aubus}/><br/><br/>
                <strong>Aubus Pro (Pty) Ltd</strong>
            </div>}>
                <TreeNode label={<div><p>Property</p></div>}>
                        <TreeNode label={<div>
                            Funeral Parlour <br/>
                            Sales Offices
                        </div>} >
                        {/* <TreeNode label={<div>
                                <a href='/' style={{textDecoration:'none'}} target='_blank'>
                                <button>
                                    Learn more
                                </button>
                                </a>
                            </div>}/> */}
                        </TreeNode>
                </TreeNode>

            </TreeNode>
        </TreeNode>
                {/* 21st Century Life */}
                <TreeNode label={<div>
            <strong>100%</strong>
        </div>}>
            <TreeNode label={<div>
                <img src={TwentyFirst}/><br/><br/>
                <strong>21<sup>st</sup> Century Events Management (Pty) Ltd</strong>
            </div>}>
                <TreeNode label={<div><p>Funeral Services</p></div>}>
                        <TreeNode label={<div>
                            Local repratriations<br/>
                            International repratriations<br/>
                            Catering and event Management<br/>
                            Caskets and consumables<br/>
                        </div>} >
                        <TreeNode label={<div>
                                <a href='https://21stcenturylife.co.za/' style={{textDecoration:'none'}} target='_blank'>
                                <button>
                                    Visit Website
                                </button>
                                </a>
                            </div>}/>
                        <TreeNode label={<div>
                                <a href='https://agrostrong.fra1.digitaloceanspaces.com/3.%2021st%20Century%20Life%20%20Funeral%20Services%20Offering.pdf' style={{textDecoration:'none'}} target='_blank'>
                                <button>
                                    Customer Offering
                                </button>
                                </a>
                            </div>}/>
                        </TreeNode>
                </TreeNode>

            </TreeNode>
        </TreeNode>
         {/* 21st Century Life */}
         <TreeNode label={<div>
            <strong>100%</strong>
        </div>}>
            <TreeNode label={<div>
                <img src={TwentyFirst}/><br/><br/>
                <strong>21<sup>st</sup> Century (Pty) Ltd</strong>
            </div>}>
                <TreeNode label={<div><p>Funeral Cover</p></div>}>
                        <TreeNode label={<div>
                            Funeral Policy Sales<br/>
                        </div>} >
                        <TreeNode label={<div>
                                <a href='https://21stcenturylife.co.za/' style={{textDecoration:'none'}} target='_blank'>
                                <button>
                                    Visit Website
                                </button>
                                </a>
                            </div>}/>
                            <TreeNode label={<div>
                                <a href='https://agrostrong.fra1.digitaloceanspaces.com/3.%2021st%20Century%20Life%20%20Funeral%20Services%20Offering.pdf' style={{textDecoration:'none'}} target='_blank'>
                                <button>
                                    Customer Offering
                                </button>
                                </a>
                            </div>}/>
                        </TreeNode>
                </TreeNode>

            </TreeNode>
        </TreeNode>
        {/* Linar Life */}
        <TreeNode label={<div>
            <strong>60%</strong>
        </div>}>
            <TreeNode label={<div>
                <img src={Linar}/><br/><br/>
               <strong> Linar life (Pty) Ltd</strong>
            </div>}>
                <TreeNode label={<div><p>Micro Insurance</p></div>}>
                        <TreeNode label={<div>
                           Long-Term Insurance<br/>
                           Short-Term Insurance<br/>
                           Health Insurance
                        </div>} >
                        {/* <TreeNode label={<div>
                                <a href='https://21stcenturylife.co.za/' style={{textDecoration:'none'}} target='_blank'>
                                <button>
                                    Learn more
                                </button>
                                </a>
                            </div>}/> */}
                        </TreeNode>
                </TreeNode>

            </TreeNode>
        </TreeNode>

    </Tree>

  )
}

export default NauficoStructure;