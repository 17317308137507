import React from 'react';
import logo from '../../assets/images/21st.png';
import img from '../../assets/images/21Century.png'

const BranchLocations = () => {
  return (
    <div className="container-contact-us">
      <h1 className="title-contact-us">Our Branch Locations</h1>
      <hr className="underline-contact-us" />
      <table className="table-contact-us">
        <thead>
          <tr>
            <th>BRANCH NAME:</th>
            <th>ADDRESS:</th>
            <th>CONTACT NO:</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Midrand Head Office</td>   
            <td>410 Janadel Avenue, Halfway Gardens</td>
            <td>(012) 285 0017 <br/> 081 013 6748</td>        
          </tr>
          <tr>
             <td>Midrand</td>
             <td>420 Janadel Avenue, Halfway Gardens</td>
             <td>011 805 1621 <br/> 081 013 6705</td>
          </tr>    
          <tr>
             <td>Benoni</td>
             <td>86 Cranbourne Avenue</td>
             <td>087 754 0625 <br/> 083 636 1643</td>
          </tr>           
          <tr>
             <td>Pretoria</td>
             <td>Shop 11, Jestet Park, Cnr Kgosi Mapuru & Johannes Romakhoase Street</td>
             <td>012 320 2300 <br/> 083 638 1971</td>
          </tr> 
          <tr>
             <td>Soweto</td>
             <td>11787 Mtipa Street, Orlando West</td>
             <td>087 754 0625 <br/> 081 013 6688</td>
          </tr> 
          <tr>
             <td>KwaThemba</td>
             <td>12 Greer Street, Shop 18, KwaThema Square</td>
             <td>087 754 0632 <br/> 081 013 6671</td>
          </tr> 
          <tr>
             <td>Tsakane</td>
             <td>27383 Ndaba Street</td>
             <td>087 754 0628 <br/> 081 013 6681</td>
          </tr> 
          <tr>
             <td>Nelspruit</td>
             <td>24 Nel Street</td>
             <td>013 762 3074 <br/> 081 013 6696</td>
          </tr> 
          <tr>
             <td>Witbank</td>
             <td>No: 5 Athlone Street, Gerry Mann's Building next to Sassa</td>
             <td>087 754 0630 <br/> 081 031 6649</td>
          </tr> 
          <tr>
             <td>Katlehong</td>
             <td>Shop 6, Gamaphutheng Shopping Centre</td>
             <td>087 754 0630 <br/> 081 013 6690</td>
          </tr> 
          <tr>
             <td>Vereeniging</td>
             <td>28A George Street</td>
             <td>087 754 0629 <br/> 063 372 9697</td>
          </tr> 
          <tr>
             <td>Cosmo City</td>
             <td>Shop 2D, Cosmo City Shopping Centre</td>
             <td>087 754 0629 <br/> 081 013 6671</td>
          </tr> 
          <tr>
             <td>Harare</td>
             <td>4th Floor, Three Anchor House, Jason Moyo Avenue</td>
             <td>+263 242 255 440 <br/> +263 242 255 441</td>
          </tr> 
          <tr>
             <td>Harare</td>
             <td>6th Floor, Greenbridge West, Eastgate Mall</td>
             <td>+263 242 255 440 <br/> +263 242 255 441</td>
          </tr> 
          <tr>
             <td>Bulawayo</td>
             <td>8 Swansea Street Belmont</td>
             <td>+263 242 255 440 <br/> +263 242 255 441</td>
          </tr> 
        </tbody>
      </table>
      <div className="ribbon-contact-us"></div>
      
    </div>
  );
};

export default BranchLocations;
