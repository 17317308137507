export const FAQSPROPERTY = [
  {
    title: "What do I need to become part of the Tsigiro Usekelo Trust Fund?",
    description:
      "You Need The Following To Have ID On The platform Email Address Or Phone Number, ID Document And Proof of Address",
  },
  {
    title: "Can I lose my property due to non-payment?",
    description:
      "The property can only be rendered yours after it has been fully paid up, in the event of non-payment and there being no payment arrangements, your allocated property is freed up from your portfolio (no longer allocated to you) and put back to the market. You will then get refunded your payments net of charges (If applicable).      ",
  },
  {
    title: "What are the benefits of joining the Tsigiro Usekelo Trust Fund?",
    description:
      "You benefit in the following ways, Your investments earn a return. The deposit required by the financial partners of the Trust Fund is reduced compared to going to them directly. You get an extended/prolonged repayment period. You get access to 21st Century Life Funeral Cover (no waiting period if you are switching from another service provider), You get access to Life Cover (in the event of death, your property is 100%paid-up and handed over to your nominated beneficiaries).",
  },
  {
    title:
      "Who is eligible for Tsigiro | Usekelo funeral and repatriation cover?",
    description:
      "Zimbabwean foreign nationals below the  ,living in South Africa are eligible for Tsigiro  | Usekelo funeral and repatriation cover. Tsigiro Usekelo is designed to cover Foreign Nationals living in South Africa, mostly Zimbabwean nationals.",
  },
  {
    title: "What if I already have a property?",
    description:
      "You can be an investor on the platform that allows for your money to accumulate a return.",
  },
  {
    title: "Can I own more than one property?",
    description:
      "Yes, you can own more than one property, however, fair allocation to the whole aTrust Fund pool will apply using fair criteria. For example, where there is only one property and 10 investors want it, the Fund together with its Partners will score the applications and allocate using fair practices, for example first time home owners where affordability is equal to all.",
  },
  {
    title: "What happens in the event of me failing to pay?",
    description:
      "In the event of death, your loan will be immediately paid upon death because of the Life Cover you take on establishment of the loan and your beneficiaries will take ownership of the paid-up property. Due to unemployment, you are supposed to notify the office and make arrangements which will be negotiated with the financial partners.",
  },
];
