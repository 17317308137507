import React from 'react';
import {FuneralConstantModules} from '../../constants/FuneralModules';

const FuneralModules = () => {
  return (
     <div className='modules-cta-container'>
        <div className='modules-title'>
            <p className='larger-text'>Our 4-In-One Benefits</p>
            <p style={{margin:0}}>A Funeral Cover that does more, there is no better time to start than now!</p>    
        </div>
    <div className='modules-cta-section'>
        {
        FuneralConstantModules.map((x,index)=>{
            return <div className='ribbon-cta-container' style={{height:290}}>
                        <div key={index} className="single-cta-module">
                            {/* <img src={x.icon} alt={"tsigiro" + x.title}/> */}
                            <h3>{x.title}</h3>
                            <p>{x.description}</p>
                            {/* <a href={x.link} style={{textDecoration:'none'}}>
                                 <p style={{margin:0,color:'#295052',fontSize:15,fontWeight:500}}>{x.buttonTitle +" >"} </p> 
                            </a> */}
                         
                    </div>
                    </div> 
        })
        }       
    </div>
    </div>
  )
}

export default FuneralModules