import React from 'react';
import { EChart } from '@hcorta/react-echarts';

const KPIChart = () => {
  return (
    <EChart
        className={'chart'}
        title={{
              text: 'KPI\'s active policies & lives covered',
              textStyle:{
                fontSize:12,
              },
              left: "center",
            }}
        legend={{top:30}}
        xAxis={{
        type: 'category'
        }}
        style={{
            height: 450,
          }}
        yAxis={{
        type: 'value',
        boundaryGap: false,
        offset:-10,
        minInterval:1,
        maxInterval:100000,
        scale:true
        }}
        
        tooltip={{
            trigger: 'axis'
        }}
        series={[
        {
            type: 'bar',
            name:'lives covered',
            color:'#00d5f5',
            data: [
            ['2019', 42209],
            ['2020', 46899],
            ['2021', 52110],
            ['2022', 57901],
            ['2023', 101889],
            ['2024', 198139],
            ['2025', 366139],
            ['2026', 579139],
            ['2027', 870139],
            ['2028', 1143139],
            ]
        },
        {
            type: 'bar',
            name:'active policies',
            color:'#004aad',
            data: [
                ['2019', 12060],
                ['2020', 13400],
                ['2021', 14889],
                ['2022', 16543],
                ['2023', 29111],
                ['2024', 56611],
                ['2025', 104611],
                ['2026', 170611],
                ['2027', 248611],
                ['2028', 326611],
            ]
        }
        ]}
  />
  )
}

export default KPIChart;