import React from 'react';
import Footer from '../components/footer/Footer';
import Navbar from '../components/navbar/Navbar';
import "../assets/css/partners.css"
import { PartnersInfo, PartnersInfo2, PartnersInfo3, PartnersInfo4 ,PartnersInfo5} from '../constants/Partners';


const Partners = () => {

  return (
    <div>
      <Navbar />
     
      <section id="partners" style={{marginTop: "80px", paddingBottom: "30px"}}>
        <div className='partners-cta-container'>
            <h2 style={{textAlign: "center", paddingTop: "5%"}}>Our Partners</h2>
            <p style={{margin:0, marginBottom: "20px", textAlign: "center"}}>Our partnership structure enables us to collaborate with top tier service providers who <br /> share the same vision of delivering quality service and solutions to our clients.
            </p>    
              <section id="partners">
       
      </section>
       <section id="partners">
        <div className='partners-cta-container' style={{paddingBottom: "30px"}}>      
          <div className='modules-cta-section'>
          {
              PartnersInfo5.map((x,index)=>{
                return <div className='ribbon-cta-container'>
                  <div key={index} className="single-cta-module">
                    <img src={x.icon} alt={"tsigiro" + x.title} width="60%"/>
                    <h3 style={{marginTop: "40px"}}>{x.title}</h3>
                    <p style={{fontSize: "13px"}}>{x.description}</p>
                  </div>
                </div> 
              })
            }         
          </div>
        </div>
       </section>
          <div className='modules-cta-section' style={{paddingTop: "5%"}}>
            {
              PartnersInfo.map((x,index)=>{
                return <div className='ribbon-cta-container'>
                  <div key={index} className="single-cta-module" style={{paddingTop: "5%", paddingBottom: "5%"}}>
                    <img src={x.icon} alt={"tsigiro" + x.title} width="60%"/>
                    <h3 style={{marginTop: "40px"}}>{x.title}</h3>
                    <p style={{fontSize: "13px"}}>{x.description}</p>
                  </div>
                </div> 
              })
            }  
          </div>
        </div>
      </section>
      <section id="partners">
        <div className='partners-cta-container' style={{paddingBottom: "30px"}}>         
         
          <div className='modules-cta-section'>
          {
              PartnersInfo2.map((x,index)=>{
                return <div className='ribbon-cta-container'>
                  <div key={index} className="single-cta-module">
                    <img src={x.icon} alt={"tsigiro" + x.title} width="60%"/>
                    <h3 style={{marginTop: "40px"}}>{x.title}</h3>
                    <p style={{fontSize: "13px"}}>{x.description}</p>
                  </div>
                </div> 
              })
            }         
          </div>
        </div>
      </section>
      <section id="partners">
        <div className='partners-cta-container' style={{paddingBottom: "30px"}}>         
         
          <div className='modules-cta-section'>
          {
              PartnersInfo3.map((x,index)=>{
                return <div className='ribbon-cta-container'>
                  <div key={index} className="single-cta-module">
                    <img src={x.icon} alt={"tsigiro" + x.title} width="60%"/>
                    <h3 style={{marginTop: "40px"}}>{x.title}</h3>
                    <p style={{fontSize: "13px"}}>{x.description}</p>
                  </div>
                </div> 
              })
            }         
          </div>
        </div>
      </section>
      <section id="partners">
        <div className='partners-cta-container' style={{paddingBottom: "30px"}}>         
         
          <div className='modules-cta-section'>
          {
              PartnersInfo4.map((x,index)=>{
                return <div className='ribbon-cta-container' style={{border:x?.icon ? "1px solid #eee":'none'}}>
                  <div key={index} className="single-cta-module" >
                    <img src={x.icon} alt={x.icon && "tsigiro" + x.title} width="60%"/>
                    <h3 style={{marginTop: "40px"}}>{x.title}</h3>
                    <p style={{fontSize: "13px"}}>{x.description}</p>
                  </div>
                </div> 
              })
            }         
          </div>
        </div>
      </section> 
      
       
         
      {/* <PartnersFaqs /> */}
      <Footer />
    </div>
  );
};

export default Partners;
