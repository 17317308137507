import PropertiesIcon from '../assets/images/PropertiesIcon.svg';
import InvestmentsIcon from '../assets/images/InvestmentsIcon.svg';
import FuneralIcon from '../assets/images/FuneralIcon.svg';

export const FuneralConstantModules = [
    {
        icon:PropertiesIcon,
        title:'Cash',
        description:`Cash pay-out of up
                    to R10000 before
                    the funeral for
                    immediate family
                    needs. `,
        // buttonTitle:'View Properties',
        link:'https://id.tsigiro.com'
    },
    {
        icon:InvestmentsIcon,
        title:'Repatriation',
        description:`Tsigiro Usekelo will
                help take your loved
                one home, anywhere
                in South Africa to
                anywhere in
                Zimbabwe.
                The repatriation can
                be carried out via air
                or land for up to
                R100000.`,
        // buttonTitle:'Start Investing',
        link:'https://id.tsigiro.com'
    },
    {
        icon:FuneralIcon,
        title:`A FUNERAL SERVICE`,
        description:`At 21st Century Life, we offer 
        funeral services in South Africa as well as 
        repatriations to Zimbabwe.nTo learn more about our funeral service offerings,
        please visit our website at www.21stcenturylife.co.za,
        21 st Century Life has been around for 25 years and with
        branches across South Africa and Zimbabwe`,
        // buttonTitle:'Get Covered',
        link:'https://id.tsigiro.com'
    },
    {
        icon:FuneralIcon,
        title:`A FUNERAL SERVICE`,
        description:`
        Our clients receive assistance from 
        us in the form of providing 100KGs 
        of beef for funerals in Zimbabwe.
         The beef can be collected from MC
          Meats or delivered to their preferred location.`,
        // buttonTitle:'Get Covered',
        link:'https://id.tsigiro.com'
    }

]