import axios from "axios";

const ApiClient = () => {

    const {REACT_APP_DEV_AUTH} = process.env;

    const axiosInstance = axios.create(
        {
            baseURL: window.location.origin.includes('tsigiro.com') || window.location.origin.includes('localhost') ?  "https://api.tsigiro.com" : REACT_APP_DEV_AUTH,
            responseType: 'json'
        }
    );

    axiosInstance.defaults.headers.post['Content-Type'] = 'application/json';

    axiosInstance.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            const message = error?.response?.data ? JSON.stringify(error?.response?.data?.message) : error.message || error;
            return Promise.reject({message: message.replace(/"/g, "")});
        }
    );

    return axiosInstance

};

export default ApiClient;