import React from 'react';
import Tick from '../../assets/images/whitetick.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import {redirectAuth} from '../../Utils/Redirect';

const InnerTemplate = (props) => {

  return (
    <div className='inner-tsigiro-products'>
        <div className={props.className}>
        </div>
        <div className='inner-tsigiro-benefits'>
            <p className='title'>{props.title}</p>
            <p>{props.description}</p>
            {
                props.features.map((x,index)=>{
                    return  <div key={index} className='benefit-container'>
                                <img src={Tick}/>
                                <p>{x}</p>
                            </div>
                })
            }
            <div style={{display:'flex',alignItems:'center',marginTop:30}}>
                <button 
                onClick={redirectAuth}
                className='btn btn-secondary or-hover' style={{marginRight:30,width:'auto'}}> 
                    <span style={{display:'flex',alignItems:'center'}}>
                        <p style={{margin:0,marginRight:10}}>Become a member</p> 
                        {/* <FontAwesomeIcon icon={faChevronRight} /> */}
                    </span>
                </button>
            </div>
        </div>
    </div>
  );

}

export default InnerTemplate