import {FullPage, Slide} from 'react-full-page';
import '../assets/css/gallery.css';
import Navbar from '../components/navbar/Navbar';
import Footer from '../components/footer/Footer';
import FAQ from '../components/faqs/FAQ';
// import {IPO} from '../components/Properties/';
import IPOpic from "../assets/images/IPO.svg";

export const IPO = () =>{
    return(
        <div class="section_ipo">
            <div class="padding-global">
            <div class="container-large">
                <div class="padding-section-medium">
                {/* <div class="padding-vertical padding-medium"></div> */}
                <div style={{marginBottom : "-5rem"}} class="ipo_grid">
                    <div id="w-node-cdc58fc2-c70f-0be7-419f-00f619f281d2-1871d271" class="ipo_content">
                    <div class="ipo_image-wrapper"><img src="images/joina.png" loading="lazy" sizes="(max-width: 479px) 100vw, (max-width: 767px) 95vw, (max-width: 991px) 43vw, 29vw" width="416" srcset="images/joina.png 500w" alt="" class="ipo_image"/></div>
                    <div class="ipo_info">
                        <img src={IPOpic}></img>
                        <div class="ipo_location">Harare</div>
                        <div class="heading-style-h4">Joina City</div>
                        <p>Joina City is the 3rd tallest building in Zimbabwe standing at 105 metres.</p>
                        <div class="padding-vertical padding-small"></div>
                        <div class="ipo_meta">
                        <div id="w-node-fb2c0446-a2c8-5886-9e96-9e0d9447e73b-1871d271" class="ipo_logo"><img src="images/Image_1.png" loading="lazy" width="48" alt="" class="image"/></div>
                        <div id="w-node-_0b15619b-4bc5-30f2-b44e-bec71858cfba-1871d271" class="ipo_values">
                            <div class="ipo_money-raised">$898 999 878</div>
                            <div class="ipo_investors">
                            <div class="text-size-small"><span class="value-span">100% </span>Oversubscribed</div>
                            <div class="ipo_seperator"></div>
                            <div class="text-size-small"><span class="value-span">8787 </span>Investors</div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
    )
}

function Properties() {
    return (
        <div>
                <Navbar/>
                <Slide>
                    <div style={{marginTop:"2rem"}}></div>
                    {
                        [1,2,3,4,5].map(x => {
                            return (
                                <IPO/>
                            )
                        })
                    }
                    <div style={{marginTop:"2rem"}}></div>
                </Slide>
                <Slide>
                    <Footer/>
                </Slide>
        </div >
    );
}

export default Properties;
