
import PG from '../assets/images/partner1.png';
import MC from '../assets/images/partner2.png';
import TB from '../assets/images/partner3.png';
import CL from '../assets/images/partner4.png';
import DK from '../assets/images/dk.png';
import AS from '../assets/images/agrostrong.png';
import MM from '../assets/images/partner8.png';
import ST from '../assets/images/partner9.png';
import AC from '../assets/images/partner7.png';
import STS from '../assets/images/partner10.png';
import MD from '../assets/images/partner11.png';
import iEN from '../assets/images/partner12.png';
import Purpose from '../assets/images/purpose.png';
import Loyd from '../assets/images/Loyd.png';
import ISO from '../assets/images/isomaster.png';
// Purpose Asset Management is a registered Asset Manager in Zimbabwe.  The company is majority owned by Vunani Limited which is listed on the Johannesburg Stock Exchange.  Tsigiro Usekelo has appointed Purpose Asset Management, to manage its funds ( Tsigiro Usekelo Founders Private Fund and Tsigiro Usekelo Property Fund -a collective investment scheme which is currently going through registration with SEC Zimbabwe).
export const PartnersInfo = [
    
    {
        icon:MC,
        title:'PG Industries.',
        description:'PG Industries (Zimbabwe) Limited manufactures and sells a range of products through three subsidiaries; PG Merchandising, Zimtile and PG Glass. Its merchandising division provides timber boards, hardware, plumbing, and wood and glass value-added products.',
        buttonTitle:'View Partner',
        link:'/properties'
    },
    {
        icon:Loyd,
        title:'Lloyd Corporate Capital',
        description:`
            Lloyd Corporate Capital provides human, equity and debt capital as well as training and advisory services businesses in Southern Africa.

            Lloyd Corporate Capital is licensed by the Securities and Exchange Commission of Zimbabwe (SEC Zimbabwe) as a Securities Investment Advisor (Corporate Finance and Investment Products) in terms of section 42 (2) of the Securities and Exchange Act (Chapter 24:25). License Number: SECZ 4545V.

            Lloyd Corporate Capital is also a Non-Member Institution (NMI) of the Zimbabwe Stock Exchange (ZSE). We facilitate investment and trading in listed and unlisted equity and quasi-equity securities.
            `,
        buttonTitle:'View Partner',
        link:'/funeral-cover'
    },
    {
        icon:TB,
        title:'Turnburry Developers.',
        description:'Turnburry Property Developers is acclaimed for its excellent delivery of quality services in Zimbabwe. From land and property evaluations and improvements to renovations and consultations, they ensure commendable property transformation.        ',
        buttonTitle:'View Partner',
        link:'/funeral-cover'
    },
]


export const PartnersInfo2 = [
    {
        icon:PG,
        title:'21st Century Life.',
        description:'21st Century life is an esteemed Funeral Cover establishment that offers the best service to cater for the unfortunate event of death. At 21st Century Life, your needs are priority before everything else. The main goal is to be your lifetime partner– because we will  always be there for you.        ',
        buttonTitle:'View Partner',
        link:'/funeral-cover'
    },
    {
        icon:AS,
        title:'Agrostrong.',
        description:'Agrostrong is a prominent entity that offers a simplified way to make savings through cattle investments which allow for the initial capital to not only maintain its value, but to grow in-line with or ahead of inflation.The savings and/or investments in cattle can also be used as collateral security for a personal or business loan.',
        buttonTitle:'View Partner',
        link:'/funeral-cover'
    },
    {
        icon:DK,
        title:'Diaspora Kapita.',
        description:'Diaspora Kapita is an established investment holding company primarily focused on investing in multiple sectors. Our vision is to be the leading Pan-African investment holding group focused on identifying, acquiring and building a portfolio of sustainable growth assets in Sub-Sahara Africa.',
        buttonTitle:'View Partner',
        link:'/funeral-cover'
    }
]


export const PartnersInfo3 = [
    {
        icon:AC,
        title:'Architertonia.',
        description:'We are an established design architects entity located in South Africa. Our architecture portfolio is broad, ranging from the design of commercial and industrial complexes to residential houses and interior design. We have a team of dedicated professionals who are committed to providing you with the best architectural designs.        ',
        buttonTitle:'View Partner',
        link:'/funeral-cover'
    },
    {
        icon:STS,
        title:'Southern Trust Securities.',
        description:'Southern Trust Securities (Private) Limited is a licenced Stock Broking firm trading on the Zimbabwe Stock Exchange (ZSE) and Victoria Falls Stock Exchange (VFEX) The company’s core business is trading in equities that are listed on both exchanges on behalf of clients.',
        buttonTitle:'View Partner',
        link:'/funeral-cover'
    },
    {
        icon:MM,
        title:'Muvingi | Mugadza.',
        description:'Muvingi & Mugadza Legal Practitioners is a distinguished award-winning law firm registered with the Law Society of Zimbabwe. Our practice has continuously expanded and evolved over the years in conformity with client’s requirements. We offer a full range of legal services owing to our diverse qualifications and specializations in the field of law.        ',
        buttonTitle:'View Partner',
        link:'/funeral-cover'
    },

]


export const PartnersInfo5 = [
    {
        icon:Purpose,
        title:'Purpose Asset Management',
        description:'Purpose Asset Management is a registered Asset Manager in Zimbabwe.  The company is majority owned by Vunani Limited which is listed on the Johannesburg Stock Exchange.  Tsigiro Usekelo has appointed Purpose Asset Management, to manage its funds ( Tsigiro Usekelo Founders Private Fund and Tsigiro Usekelo Property Fund -a collective investment scheme which is currently going through registration with SEC Zimbabwe).',
        buttonTitle:'View Partner',
        link:'/funeral-cover'
    },
    {
        icon:ISO,
        title:'Isomaster',
        buttonTitle:'View Partner',
        description:`Isomaster ,is a registered leading fuel wholesale supplier in Midrand, South Africa. The company specializes in providing high-quality diesel and petrol at unbeatable wholesale prices, catering to businesses across Gauteng, Mpumalanga, the Free State, and the North West regions. With a commitment to exceptional service and substantial savings, Isomaster is your trusted partner for all your fuel needs.`,
        link:'/funeral-cover'
    }
]


export const PartnersInfo4 = [
    {
        icon:MD,
        title:'Medichem Health.',
        description:'Medichem Health is health sector investment initiative by Zimbabweans in the Diaspora partnering with local Zimbabweans. It is passionate about provision of timely access to health services for everyone and availing affordable health products and services. Through Medichem Pharmacies in Zimbabwe we offer Zimbabwean Diaspora convenience of paying for medicines for families in Zimbabwe whilst in the Diaspora, with an option of delivery or collection of the medication in Zimbabwe.',
        buttonTitle:'View Partner',
        link:'/funeral-cover'
    },
    {
        icon:ST,
        title:'Systech Private Limited.',
        description:'Systech is a privately owned entity involved in the supply and distribution of building and construction materials in and around Zimbabwe. Our product range also includes general hardware, plumbing supplies, adhesives; right through to roofing materials such as IBR sheets and other building accessories.  ',
        buttonTitle:'View Partner',
        link:'/funeral-cover'
    },  
    {
        icon:iEN,
        title:'iEnsure Digital.',
        description:'iEnsure is a full-service platform offering you a top-up cover for your funeral plan, boosted with a data and device package all under one premium. This affordable cover enables clients to access mobile devices and MTN mobile data at affordable prices, on pay-as-you-go or contract basis. The iEnsure Cash + Data + device plan is a solution giving you consistently reliable connectivity and top-of-the-range devices with the added security of a cash payout when you need it most.',
        buttonTitle:'View Partner',
        link:'/funeral-cover' 
    }

]