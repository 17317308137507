import React from "react";
import "../../assets/css/about.css";

function Second() {
  return (
    <div class="about-container-second" style={{ backgroundColor: "#F8FCFC" }}>
      <div class="about-column-1 box">
        <h1 className="hero-third-heading" style={{fontWeight:500}}>
          Helping  all Zimbabwean Diaspora to build a home while away from home.
        </h1>
      </div>
      <div class="about-column-2 box">
        <p style={{fontSize:20}}>
          We understand that property acquisition (or ownership) in Zimbabwe can
          be extremely challenging, as research has it that not only do
          Zimbabwean banks often require an approximate deposit of 30% for a
          mortgage but also impose a limited period for paying off the mortgage
          which not only forsters limited access to funds but can also cause
          financial strain for the majority of the diaspora.
        </p>
        <p style={{fontSize:20}}>
          Additionally, statistics from The Reserve Bank of Zimbabwe show that
          approximately 2.5% of Zimbabweans have a mortgage leaving the majority
          of the population without access to adequate financing for property
          ownership.
        </p>
      </div>
    </div>
  );
}

export default Second;
