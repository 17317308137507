import React, { useRef } from 'react'
import Navbar from '../components/navbar/Navbar';
import { CarouselProvider, Slider, Slide, DotGroup, Dot } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import LandIcon from '../assets/images/icons/land.svg';
import CoverIcon from '../assets/images/icons/lock.svg';
import GroupIcon from '../assets/images/icons/group.svg';
import MarketIcon from '../assets/images/icons/market.svg';
import Growth from '../assets/images/icons/growth.svg';
import CommunityIcon from '../assets/images/icons/community.svg';
import MapIcon from '../assets/images/icons/map.svg';
import Footer from '../components/footer/Footer';
import Tick from '../assets/images/icons/tick.svg';

const Agrostrong = () => {



    return (
        <div>
            <Navbar />
            <div className='agrostrong-bg'>
                <h1 style={{ color: '#fff', fontWeight: 600 }}>Cattle Backed Investments</h1>
            </div>
            <div className='agro-hero-2' >
                <h1 className='title'>Agrostrong</h1>
                <p>
                    AgroStrong, an innovative Agri-Tech firm, is revolutionizing Africa's financial sector with
                    groundbreaking cattle-backed financial services. Cattle, esteemed for its exceptional
                    attributes including capital preservation, high yield, and liquidity, serve as the
                    cornerstone of this endeavor. Investors engaging with our offering have the potential
                    to earn impressive annual returns of up to 16% in stable currency, leveraging the
                    convertibility and liquidity of cattle assets
                </p>
                <p>
                    AgroStrong proudly introduces its groundbreaking initiative, the “Remote Cattle Farmer,"
                    enabling individuals and companies worldwide to participate in cattle farming from the
                    comfort of their homes or offices. Say goodbye to the heavy lifting associated with breeding cattle, as AgroStrong takes care of it all on your behalf, offering a unique opportunity to diversify your investment portfolio.
                </p>
                <div class="how-it-works_button-group" style={{ marginTop: 30 }}>
                    <a href="https://agrostrong.tsigiro.com" target='_blank'>
                        <button className="btn btn-secondary">
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-around",
                                    alignItems: "center",
                                }}
                            >
                                <p style={{ margin: 0 }}>Get Started</p>
                            </div>
                        </button>
                    </a>
                </div>
            </div>
            <div className='agro-cards-container'>
                <div className='agro-cards-flex'>
                    <div >
                        <p className='larger-text'>Feedlot Programme</p>
                        <p>
                            Invest in our feedlot programme, focusing on short-term cycles lasting<br></br> between 90 and 140 days.
                        </p>
                        <div className='benefit-container' style={{ marginTop: 0, justifyContent: 'center' }}>
                            <img src={Tick} />
                            <p style={{ fontSize: 12 }} >Minimum Investment amount of US$10 /R200 per month</p>
                        </div>
                        <div className='benefit-container' style={{ marginTop: 0, justifyContent: 'center' }}>
                            <img src={Tick} />
                            <p style={{ fontSize: 12 }} >Gradually grow your investment.</p>
                        </div>
                        <div className='agro-cards-flex'>
                            <div className='agro-card-inner'>
                                <div className='bg-feedlot'></div>
                                <h3 style={{ fontSize: 15, fontWeight: 500, marginTop: '2%' }}>Feedlot Programme</h3>
                                <p className='agro-card-title' style={{ fontSize: 13, margin: 0, marginTop: 3 }}>Target Return :  14% (annual) + Discretionary Bonus </p>
                                <p style={{ fontSize: 13, margin: 0, marginTop: 3 }}>Investment Period : 90 Days </p>

                                <h3 style={{ fontSize: 15, fontWeight: 500, }}> Returns & Terms</h3>
                                <div style={{ padding: 3 }}>
                                    <div className='benefit-container' style={{ marginTop: 0 }}>
                                        <img src={Tick} />
                                        <p style={{ fontSize: 12, textAlign: 'left' }} >Minimum investment period of 90 Days (Zimbabwe) or 140 days (South Africa)</p>
                                    </div>
                                    <div className='benefit-container' style={{ marginTop: 0 }}>
                                        <img src={Tick} />
                                        <p style={{ fontSize: 12, textAlign: 'left' }} >Distributions every cycle (90 Days or 140 days).</p>
                                    </div>
                                    <div className='benefit-container' style={{ marginTop: 0 }}>
                                        <img src={Tick} />
                                        <p style={{ fontSize: 12, textAlign: 'left' }} >Target annualized base return of 14%
                                        </p>
                                    </div>
                                    <div className='benefit-container' style={{ marginTop: 0 }}>
                                        <img src={Tick} />
                                        <p style={{ fontSize: 12, textAlign: 'left' }} > Potential for performance bonus above the base return of 14%.</p>
                                    </div>
                                </div>
                                <h3 style={{ fontSize: 15, fontWeight: 500, padding: 10 }}> Our Remote Cattle Farmer covers the following</h3>
                                <div style={{ padding: 3 }}>
                                    <div className='benefit-container' style={{ marginTop: 0 }}>
                                        <img src={Tick} />
                                        <p style={{ fontSize: 12 }} >Experienced cattle management</p>
                                    </div>
                                    <div className='benefit-container' style={{ marginTop: 0 }}>
                                        <img src={Tick} />
                                        <p style={{ fontSize: 12 }} >Insurance</p>
                                    </div>
                                    <div className='benefit-container' style={{ marginTop: 0 }}>
                                        <img src={Tick} />
                                        <p style={{ fontSize: 12 }} >Infrastructure.</p>
                                    </div>
                                    <div className='benefit-container' style={{ marginTop: 0 }}>
                                        <img src={Tick} />
                                        <p style={{ fontSize: 12, textAlign: 'left' }} > Feed & Medicines.</p>
                                    </div>
                                    <div className='benefit-container' style={{ marginTop: 0 }}>
                                        <img src={Tick} />
                                        <p style={{ fontSize: 12, textAlign: 'left' }} > You can use your cattle as security for a loan..</p>
                                    </div>
                                    <div className='benefit-container' style={{ marginTop: 0 }}>
                                        <img src={Tick} />
                                        <p style={{ fontSize: 12, textAlign: 'left' }} > Client visits are welcomed by those investing a minimum of 10 cattle.</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div >
                        <p className='larger-text'>Breeding Programme</p>
                        <p>
                            We are offering 250 breeding cows on our farms
                            in Matabeleland South, Zimbabwe.
                            <br></br>Minimum commitment:
                        </p>
                        <div className='benefit-container' style={{ marginTop: 0, justifyContent: 'center' }}>
                            <img src={Tick} />
                            <p style={{ fontSize: 12 }} >One Breeding Cow for US$1,700 (includes all costs for 3 years).</p>
                        </div>
                        <div className='benefit-container' style={{ marginTop: 0, justifyContent: 'center' }}>
                            <img src={Tick} />
                            <p style={{ fontSize: 12 }} >Payable either as a lump sum or spread over 4 months at US$425 per month.</p>
                        </div>
                        <div className='agro-cards-flex'>
                            <div className='agro-card-inner'>
                                <div className='bg-breeding'></div>
                                <h3 style={{ fontSize: 15, fontWeight: 500, marginTop: '2%' }}>Breeding Programme- Nkone</h3>
                                <p style={{ fontSize: 13, margin: 0, marginTop: 3 }}>Target Return : 16% (annual) + Discretionary Bonus</p>
                                <p style={{ fontSize: 13, margin: 0, marginTop: 3 }}>Investment Period : 3 Years </p>
                                <h3 style={{ fontSize: 15, fontWeight: 500 }}> Returns & Terms</h3>
                                <div style={{ padding: 3 }}>
                                    <div className='benefit-container' style={{ marginTop: 0 }}>
                                        <img src={Tick} />
                                        <p style={{ fontSize: 12 }} >	Minimum investment period of 3 years.</p>
                                    </div>
                                    <div className='benefit-container' style={{ marginTop: 0 }}>
                                        <img src={Tick} />
                                        <p style={{ fontSize: 12 }} >Dividends disbursed after the third year.</p>
                                    </div>
                                    <div className='benefit-container' style={{ marginTop: 0 }}>
                                        <img src={Tick} />
                                        <p style={{ fontSize: 12 }} >	Target annualized base return of 16%.</p>
                                    </div>
                                    <div className='benefit-container' style={{ marginTop: 0 }}>
                                        <img src={Tick} />
                                        <p style={{ fontSize: 12, textAlign: 'left' }} > Potential for performance bonus above the base return of 16%.</p>
                                    </div>
                                </div>
                                <h3 style={{ fontSize: 15, fontWeight: 500, padding: 10 }}> Our Remote Cattle Farmer covers the following</h3>
                                <div style={{ padding: 3 }}>
                                    <div className='benefit-container' style={{ marginTop: 0 }}>
                                        <img src={Tick} />
                                        <p style={{ fontSize: 12 }} >Experienced cattle management</p>
                                    </div>
                                    <div className='benefit-container' style={{ marginTop: 0 }}>
                                        <img src={Tick} />
                                        <p style={{ fontSize: 12 }} >Insurance</p>
                                    </div>
                                    <div className='benefit-container' style={{ marginTop: 0 }}>
                                        <img src={Tick} />
                                        <p style={{ fontSize: 12 }} >Infrastructure.</p>
                                    </div>
                                    <div className='benefit-container' style={{ marginTop: 0 }}>
                                        <img src={Tick} />
                                        <p style={{ fontSize: 12, textAlign: 'left' }} > Feed & Medicines.</p>
                                    </div>
                                    <div className='benefit-container' style={{ marginTop: 0 }}>
                                        <img src={Tick} />
                                        <p style={{ fontSize: 12, textAlign: 'left' }} > You can use your cattle as security for a loan..</p>
                                    </div>
                                    <div className='benefit-container' style={{ marginTop: 0 }}>
                                        <img src={Tick} />
                                        <p style={{ fontSize: 12, textAlign: 'left' }} > Client visits are welcomed by those investing a minimum of 10 cattle.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='agro-card-inner'>
                                <div className='bg-breeding-2'></div>
                                <h3 style={{ fontSize: 15, fontWeight: 500, marginTop: '2%' }}>Breeding Programme- Tuli</h3>
                                <p style={{ fontSize: 13, margin: 0, marginTop: 3 }}>Target Return : 16% (annual) + Discretionary Bonus</p>
                                <p style={{ fontSize: 13, margin: 0, marginTop: 3 }}>Investment Period : 3 Years </p>
                                <h3 style={{ fontSize: 15, fontWeight: 500 }}> Returns & Terms</h3>
                                <div style={{ padding: 3 }}>
                                    <div className='benefit-container' style={{ marginTop: 0 }}>
                                        <img src={Tick} />
                                        <p style={{ fontSize: 12 }} >	Minimum investment period of 3 years.</p>
                                    </div>
                                    <div className='benefit-container' style={{ marginTop: 0 }}>
                                        <img src={Tick} />
                                        <p style={{ fontSize: 12 }} >Dividends disbursed after the third year.</p>
                                    </div>
                                    <div className='benefit-container' style={{ marginTop: 0 }}>
                                        <img src={Tick} />
                                        <p style={{ fontSize: 12 }} >	Target annualized base return of 16%.</p>
                                    </div>
                                    <div className='benefit-container' style={{ marginTop: 0 }}>
                                        <img src={Tick} />
                                        <p style={{ fontSize: 12, textAlign: 'left' }} > Potential for performance bonus above the base return of 16%.</p>
                                    </div>
                                </div>
                                <h3 style={{ fontSize: 15, fontWeight: 500, padding: 10 }}> Our Remote Cattle Farmer covers the following</h3>
                                <div style={{ padding: 3 }}>
                                    <div className='benefit-container' style={{ marginTop: 0 }}>
                                        <img src={Tick} />
                                        <p style={{ fontSize: 12 }} >Experienced cattle management</p>
                                    </div>
                                    <div className='benefit-container' style={{ marginTop: 0 }}>
                                        <img src={Tick} />
                                        <p style={{ fontSize: 12 }} >Insurance</p>
                                    </div>
                                    <div className='benefit-container' style={{ marginTop: 0 }}>
                                        <img src={Tick} />
                                        <p style={{ fontSize: 12 }} >Infrastructure.</p>
                                    </div>
                                    <div className='benefit-container' style={{ marginTop: 0 }}>
                                        <img src={Tick} />
                                        <p style={{ fontSize: 12, textAlign: 'left' }} > Feed & Medicines.</p>
                                    </div>
                                    <div className='benefit-container' style={{ marginTop: 0 }}>
                                        <img src={Tick} />
                                        <p style={{ fontSize: 12, textAlign: 'left' }} > You can use your cattle as security for a loan..</p>
                                    </div>
                                    <div className='benefit-container' style={{ marginTop: 0 }}>
                                        <img src={Tick} />
                                        <p style={{ fontSize: 12, textAlign: 'left' }} > Client visits are welcomed by those investing a minimum of 10 cattle.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='agro-benefits-container agro-bigger'>
                <p className='larger-text'>Consider <span className='inner-border'>investing with AgroStrong<hr></hr></span> for the following compelling reasons</p>
                <CarouselProvider
                    totalSlides={7}
                    visibleSlides={3}
                    isIntrinsicHeight
                    naturalSlideWidth={100}
                >
                    <Slider  style={{width:900}}>
                        <Slide index={0} style={{ height: 300, width: 300, margin: 5 }} className='slide-card'>
                            <img src={LandIcon} style={{ marginTop: 30 }} />
                            <h3 style={{ fontSize: 15, fontWeight: 500, marginTop: '10%' }}>
                                Abundant Land Access
                            </h3>
                            <p style={{ fontSize: 12 }}>AgroStrong has secured access to 20,000 hectares of land in Matabeleland,
                                with active operations spanning 2,000 hectares.
                            </p>
                        </Slide>
                        <Slide index={1} style={{ height: 300, width: 300, margin: 5 }} className='slide-card-dark'>
                            <img src={CoverIcon} style={{ marginTop: 30 }} />
                            <h3 style={{ fontSize: 15, fontWeight: 500, marginTop: '10%' }}>
                                Comprehensive Insurance Coverage
                            </h3>
                            <p style={{ fontSize: 12 }}>
                                Protect your investment with insurance coverage against theft, diseases, and drought.
                            </p>
                        </Slide>
                        <Slide index={2} style={{ height: 300, width: 300, margin: 5 }} className='slide-card'>
                            <img src={GroupIcon} style={{ marginTop: 30 }} />
                            <h3 style={{ fontSize: 15, fontWeight: 500, marginTop: '10%' }}>
                                Experienced Management
                            </h3>
                            <p style={{ fontSize: 12 }}>Benefit from a seasoned management team equipped to efficiently scale the business.
                            </p>
                        </Slide>
                        <Slide index={3} style={{ height: 300, width: 300, margin: 5 }} className='slide-card-dark'>
                            <img src={MarketIcon} style={{ marginTop: 30 }} />
                            <h3 style={{ fontSize: 15, fontWeight: 500, marginTop: '10%' }}>
                                Market Access
                            </h3>
                            <p style={{ fontSize: 12 }}>AgroStrong ensures a dependable off-take mechanism, where the breeding program seamlessly feeds into cattle fattening for steers or breeding heifers.
                            </p>
                        </Slide>
                        <Slide index={4} style={{ height: 300, width: 300, margin: 5 }} className='slide-card'>
                            <img src={Growth} style={{ marginTop: 30 }} />
                            <h3 style={{ fontSize: 15, fontWeight: 500, marginTop: '10%' }}>
                                Indigenous Breeds
                            </h3>
                            <p style={{ fontSize: 12 }}> AgroStrong prioritizes the growth of sustainable indigenous breeds of Southern Africa, particularly the Nkone and Tuli breeds, renowned for their suitability and adaptability to the local environment.
                            </p>
                        </Slide>
                        <Slide index={5} style={{ height: 300, width: 300, margin: 5 }} className='slide-card-dark'>
                            <img src={CommunityIcon} style={{ marginTop: 30 }} />
                            <h3 style={{ fontSize: 15, fontWeight: 500, marginTop: '10%' }}>
                                Community Involvement
                            </h3>
                            <p style={{ fontSize: 12 }}> We actively support the communities surrounding our operations in developing, improving, and growing their cattle herds. This support includes providing a ready market, farmer cattle feeding schemes, disease control, and business management skills.
                            </p>
                        </Slide>
                        <Slide index={6} style={{ height: 300, width: 300, margin: 5 }} className='slide-card'>
                            <img src={MapIcon} style={{ marginTop: 30 }} />
                            <h3 style={{ fontSize: 15, fontWeight: 500, marginTop: '10%' }}>
                                Global Accessibility
                            </h3>
                            <p style={{ fontSize: 12 }}> Invest from anywhere in the world through our user-friendly proprietary platform, accessible via https://agrostrong.tsigiro.com/  or www.agrostrong.net  under the "My Account" option.
                            </p>
                        </Slide>
                    </Slider>
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: 30 }}>
                        <Dot slide={0} style={{ borderRadius: 10, height: 10, width: 1, marginRight: 5 }} />
                        <Dot slide={3} style={{ borderRadius: 10, height: 10, width: 1, marginRight: 5 }} />
                        <Dot slide={6} style={{ borderRadius: 10, height: 10, width: 1, marginRight: 5 }} />
                    </div>
                </CarouselProvider>
                <div class="how-it-works_button-group" style={{ marginTop: 30 }}>
                    <a href="https://agrostrong.tsigiro.com" target='_blank'>
                        <button className="btn btn-secondary">
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-around",
                                    alignItems: "center",
                                }}
                            >
                                <p style={{ margin: 0 }}>Get Started</p>
                            </div>
                        </button>
                    </a>
                </div>
            </div>
            <div className='agro-benefits-container agro-smaller'>
                <p className='larger-text'>Consider <span className='inner-border'>investing with AgroStrong<hr></hr></span> for the following compelling reasons</p>
                <CarouselProvider
                    totalSlides={7}
                    visibleSlides={1}
                    isIntrinsicHeight
                    naturalSlideWidth={100}
                >
                    <Slider  style={{width:300}}>
                        <Slide index={0} style={{ height: 300, width: 300, margin: 5 }} className='slide-card'>
                            <img src={LandIcon} style={{ marginTop: 30 }} />
                            <h3 style={{ fontSize: 15, fontWeight: 500, marginTop: '10%' }}>
                                Abundant Land Access
                            </h3>
                            <p style={{ fontSize: 12 }}>AgroStrong has secured access to 20,000 hectares of land in Matabeleland,
                                with active operations spanning 2,000 hectares.
                            </p>
                        </Slide>
                        <Slide index={1} style={{ height: 300, width: 300, margin: 5 }} className='slide-card-dark'>
                            <img src={CoverIcon} style={{ marginTop: 30 }} />
                            <h3 style={{ fontSize: 15, fontWeight: 500, marginTop: '10%' }}>
                                Comprehensive Insurance Coverage
                            </h3>
                            <p style={{ fontSize: 12 }}>
                                Protect your investment with insurance coverage against theft, diseases, and drought.
                            </p>
                        </Slide>
                        <Slide index={2} style={{ height: 300, width: 300, margin: 5 }} className='slide-card'>
                            <img src={GroupIcon} style={{ marginTop: 30 }} />
                            <h3 style={{ fontSize: 15, fontWeight: 500, marginTop: '10%' }}>
                                Experienced Management
                            </h3>
                            <p style={{ fontSize: 12 }}>Benefit from a seasoned management team equipped to efficiently scale the business.
                            </p>
                        </Slide>
                        <Slide index={3} style={{ height: 300, width: 300, margin: 5 }} className='slide-card-dark'>
                            <img src={MarketIcon} style={{ marginTop: 30 }} />
                            <h3 style={{ fontSize: 15, fontWeight: 500, marginTop: '10%' }}>
                                Market Access
                            </h3>
                            <p style={{ fontSize: 12 }}>AgroStrong ensures a dependable off-take mechanism, where the breeding program seamlessly feeds into cattle fattening for steers or breeding heifers.
                            </p>
                        </Slide>
                        <Slide index={4} style={{ height: 300, width: 300, margin: 5 }} className='slide-card'>
                            <img src={Growth} style={{ marginTop: 30 }} />
                            <h3 style={{ fontSize: 15, fontWeight: 500, marginTop: '10%' }}>
                                Indigenous Breeds
                            </h3>
                            <p style={{ fontSize: 12 }}> AgroStrong prioritizes the growth of sustainable indigenous breeds of Southern Africa, particularly the Nkone and Tuli breeds, renowned for their suitability and adaptability to the local environment.
                            </p>
                        </Slide>
                        <Slide index={5} style={{ height: 300, width: 300, margin: 5 }} className='slide-card-dark'>
                            <img src={CommunityIcon} style={{ marginTop: 30 }} />
                            <h3 style={{ fontSize: 15, fontWeight: 500, marginTop: '10%' }}>
                                Community Involvement
                            </h3>
                            <p style={{ fontSize: 12 }}> We actively support the communities surrounding our operations in developing, improving, and growing their cattle herds. This support includes providing a ready market, farmer cattle feeding schemes, disease control, and business management skills.
                            </p>
                        </Slide>
                        <Slide index={6} style={{ height: 300, width: 300, margin: 5 }} className='slide-card'>
                            <img src={MapIcon} style={{ marginTop: 30 }} />
                            <h3 style={{ fontSize: 15, fontWeight: 500, marginTop: '10%' }}>
                                Global Accessibility
                            </h3>
                            <p style={{ fontSize: 12 }}> Invest from anywhere in the world through our user-friendly proprietary platform, accessible via https://agrostrong.tsigiro.com/  or www.agrostrong.net  under the "My Account" option.
                            </p>
                        </Slide>
                    </Slider>
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: 30 }}>
                        <Dot slide={0} style={{ borderRadius: 10, height: 10, width: 1, marginRight: 5 }} />
                        <Dot slide={1} style={{ borderRadius: 10, height: 10, width: 1, marginRight: 5 }} />
                        <Dot slide={2} style={{ borderRadius: 10, height: 10, width: 1, marginRight: 5 }} />
                        <Dot slide={3} style={{ borderRadius: 10, height: 10, width: 1, marginRight: 5 }} />
                        <Dot slide={4} style={{ borderRadius: 10, height: 10, width: 1, marginRight: 5 }} />
                        <Dot slide={5} style={{ borderRadius: 10, height: 10, width: 1, marginRight: 5 }} />
                        <Dot slide={6} style={{ borderRadius: 10, height: 10, width: 1, marginRight: 5 }} />
                    </div>

                </CarouselProvider>
                <div class="how-it-works_button-group" style={{ marginTop: 30 }}>
                    <a href="https://agrostrong.tsigiro.com" target='_blank'>
                        <button className="btn btn-secondary">
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-around",
                                    alignItems: "center",
                                }}
                            >
                                <p style={{ margin: 0 }}>Get Started</p>
                            </div>
                        </button>
                    </a>
                </div>
            </div>
            <Footer />
        </div >
    )
};

export default Agrostrong;