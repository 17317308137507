export const MenuItems = [
    {
        title: 'Products and Services',
        linked: false,
        hasChildren: true,
        children: [
            {
                title: 'Property',
                description: 'Property Investments made simple and accessible',
                link: '/properties'
            },
            {
                title: 'Cattle Investments',
                description: 'Become a Remote Cattle Farmer',
                link: '/agrostrong'
            },
            {
                title: 'Funeral Cover',
                description: 'Diginified funeral and financial services',
                link: '/funeral-cover',
            },
            // {
            //     title:'Groceries',
            //     description:'Buy groceries',
            //     link:'https://ahoyiafrica.com/',
            // },
            {
                title: 'Construction Materials',
                description: 'Buy construction materials',
                link: 'http://systech.tsigiro.com/',
            },

        ]
    },
    {
        title: 'Contact',
        linked: true,
        link: '/contact',
        hasChildren: false
    },
    {
        title: 'About Us',
        linked: true,
        link: '/about-us',
        hasChildren: false
    },
    {
        title: 'Gallery',
        linked: true,
        link: '/gallery',
        hasChildren: false
    },
    // {
    //     title:'Partners',
    //     linked:true,
    //     link:'/partners',
    //     hasChildren:false
    // },
    {
        title: 'Advisors',
        linked: true,
        link: '/board-of-advisors',
        hasChildren: false
    }
]

export const MobileMenuITems = [
    {
        title: 'Home',
        linked: false,
        hasChildren: true,
        link: '/'
    },
    {
        title: 'Property',
        linked: false,
        hasChildren: true,
        link: '/properties'
    },
    {
        title: 'Cattle Investments',
        description: 'Become a Remote Cattle Farmer',
        link: '/agrostrong',
        linked: false,
        hasChildren: true,
    },
    {
        title: 'Funeral Cover',
        linked: false,
        hasChildren: true,
        link: '/funeral-cover'
    },
    {
        title: 'Groceries',
        linked: false,
        hasChildren: true,
        link: 'https://ahoyiafrica.com/'
    },
    {
        title: 'Construction Materials',
        linked: false,
        hasChildren: true,
        link: 'http://systech.tsigiro.com/'
    },
    // {
    //     title:'Partners',
    //     linked:true,
    //     link:'/partners',
    //     hasChildren:false
    // },
    {
        title: 'Contact',
        linked: true,
        link: '/contact',
        hasChildren: false,
    },
    {
        title: 'About Us',
        linked: true,
        link: '/about-us',
        hasChildren: false
    },
    {
        title: 'Gallery',
        linked: true,
        link: '/gallery',
        hasChildren: false
    },
    {
        title: 'Advisors',
        linked: true,
        link: '/board-of-advisors',
        hasChildren: false
    }

]