import Invest1 from "../assets/images/investments1.svg";
import Invest2 from "../assets/images/investments2.svg";
import Invest3 from "../assets/images/investments3.svg";
import Invest4 from "../assets/images/investments4.svg";

export const InvestmentsData = [
  {
    icon: Invest1,
    title: "Low Costs",
    description:
      "Our straightforward and transparent cost structure is designed to optimize your returns",
    buttonTitle: "Start Investing",
  },
  {
    icon: Invest2,
    title: "Invest Globally",
    description:
      "Our constant focus on building partnerships enables us to provide you with access to the global investment universe at your fingertips, leading to a diversified portfolio.",
    buttonTitle: "Start Investing",
  },
  {
    icon: Invest3,
    title: "Support",
    description:
      "Our  prime purpose is to provide you with as much support as we can offer so that you may be at ease and know that we have your back and we care about your investments. You can always bank on us to be there when you need us. Our mediums of communication are available 24/7.      ",
    buttonTitle: "Get Started",
  },
  {
    icon: Invest4,
    title: "Security",
    description:
      "We are fueled by the need to protect and serve you, so be rest assured that we have your best interests at heart. That is why we have set up top tier security measures to protect your investments as well as provide adequate transparency and accountability that you require in all our conduct.      ",
    buttonTitle: "Get Started",
  },
];
