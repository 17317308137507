import PropertiesIcon from '../assets/images/PropertiesIcon.svg';
import InvestmentsIcon from '../assets/images/InvestmentsIcon.svg';
import FuneralIcon from '../assets/images/FuneralIcon.svg';

export const Products = [
    {
        icon:PropertiesIcon,
        title:'Properties',
        description:'Our platform simplifies the process of buying a house or building a home in Zimbabwe -offering access to financing, building materials, and construction services under one roof. Save time,',
        buttonTitle:'View Properties',
        link:'https://id.tsigiro.com'
    },
    {
        icon:InvestmentsIcon,
        title:'Investments',
        description:' As part of the community, you have access to diverse investment opportunities, including cattle-backed financial services offered both in South Africa and Zimbabwe.',
        buttonTitle:'Start Investing',
        link:'https://id.tsigiro.com'
    },
    {
        icon:FuneralIcon,
        title:'Funeral Cover',
        description:'Access funeral cover and services from our sister company 21st Century Life. 21st Century Life ensures repatriation and full burial services in South Africa and Zimbabwe, providing peace of mind during difficult times. ',
        buttonTitle:'Get Covered',
        link:'https://id.tsigiro.com'
    }
]