import React, {useState, useEffect} from 'react'
import "../assets/css/bio-section.css"
import Footer from '../components/footer/Footer'
import Navbar from '../components/navbar/Navbar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';

function BioSection(props) {
  const [state, setState] = useState({ bios: {} });

  useEffect(() => {
    setState({ bios: props.location.state.bios });

    const handlePopstate = () => {
      window.location.reload();
    };
    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, [props.location.state.bios]);

  return (
    <>
    <Navbar />
    <div className='bio-section-container'>
    <div className="bio-section">
    <div className='bio-image'>
    <img src={state?.bios?.img} alt={state?.bios?.name} className="bio-image"/>     
    </div>
    <div className='bio-information'>
    <h2>{state?.bios?.name}</h2>
    <h6 style={{color: "#1f3846"}}>{state?.bios?.position}</h6>
    <p style={{fontSize: "14px"}}>
    {state?.bios?.bio}
    </p>
    {state?.bios?.qualifications?.length > 0 && (
      <>
        <h6 style={{color: "#1f3846"}}>Qualifications</h6>
        <ul style={{fontSize: "14px"}}>
          {state.bios.qualifications.map((x) => (<li>{x}</li>))}
        </ul>
      </>
    )}
    <a href={state?.bios?.iconLink} target="_blank" rel='noreferrer' style={{color: '#1F3846'}}><FontAwesomeIcon icon={faLinkedin} style={{fontSize:35}} size="xl" className="event-description"/></a>
    </div>
  </div>
  </div>
  <Footer />
  </>
  )
}

export default BioSection
