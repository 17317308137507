import React, { useEffect, useState } from 'react'
import Navbar from '../components/navbar/Navbar';
import InvestHero from '../components/heroes/InvestHero';
import '../assets/css/invest.css';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import ProgressBar from "@ramonak/react-progress-bar";
import Footer from '../components/footer/Footer';
import InvestmentsFaqs from '../components/faqs/InvestmentsFAQS';
import { useQuery } from 'react-query';
import Fundraiser from '../api/Fundraiser';
import DkIMage from '../assets/images/dkbg.jpg';
import CompanyStructure from '../components/invest/CompanyStructure';
import NauficoStructure from '../components/invest/NauficoStructure';
import Strat from '../assets/images/strat.png';
import DownArrow from '../assets/images/downarrow.svg';
import Size from '../assets/images/size.png';
import RightArrow from '../assets/images/right-arrow.svg';
import Money from '../assets/images/money.svg';
import Sendhome from '../assets/images/sendhome.png';
import TwentyFirst from '../assets/images/212st.png';
import Agrostrong from '../assets/images/agrostrong.png';
import Tsigiro from '../assets/images/tsig.png';
import SwitchArrow from '../assets/images/switch-arrow.svg';
import KPIChart from '../components/invest/KPIChart';
import RevenueChart from '../components/invest/RevenueChart';
import DividendsChart from '../components/invest/DividendsChart';
import Jamboo from '../assets/images/jamboo.png';
import BFCapitalStructure from '../assets/images/structureBG.png';
import NAFUICO from '../assets/images/nafuicost.png';
import News from '../assets/images/news.png';

const encodeBase64 = (data) => {
  if (typeof btoa === "function") {
    return btoa(data);
  } else if (typeof Buffer === "function") {
    return Buffer.from(data, "utf-8").toString("base64");
  } else {
    throw new Error("Failed to determine the platform specific encoder");
  }
}

const Invest = () => {
  const [currency, setCurrency] = useState('ZAR');
  const [state, setState] = useState({});
  const [customAmount, setCustomAmount] = useState();
  const [disabled, setDisabled] = useState(false);
  const [transaction, setTransaction] = useState({});
  const [netcashAmountValue,setNetCashAmount] = useState();

  const currencyFixer = (num) => {
    const numberTobeChanged = isNaN(num) ? num : parseInt(num)
    return numberTobeChanged?.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  };

  const sharePriceCalculator = (amount) => {
    const sharePrice = state.fundRaiser?.entity?.sharePrice || 0;
    return amount / sharePrice;
  };

  const transactionId = () => {
    const globalName = (state.fundRaiser?.entity?.entityName || "").replace(/\s/g, '');;

    const stringId = globalName.substring(0, 3).toLowerCase();

    const id = stringId + '-' + (Math.floor(Math.random() * 9000000) + 1000000);

    const encoded = encodeBase64(id);

    return { id, encoded };
  };

  useEffect(() => {

    const id = transactionId();

    setTransaction(id)

  }, [])

  const { error, isLoading } = useQuery('investments', Fundraiser.fetchFundraiserEntity,
    {
      onSuccess: (response) => {
        const { data } = response;
        const currency = data.fundRaiser?.entity?.entityCountry === "South Africa" ? 'R' : 'USD';
        const globalName = (data.fundRaiser?.entity?.entityName || "").replace(/\s/g, '');
        const stringId = globalName.toUpperCase();
        const id = stringId + '-' + (Math.floor(Math.random() * 900000000) + 100000000);
        const encoded = encodeBase64(id);
        setTransaction({ id, encoded });
        setState({ ...data, currency });
      }
    }
  );

  const addPercentageToAmount = (amount) => {
    const decimal = state.fundRaiser?.charge ? state.fundRaiser?.charge / 100 : 0;
    const addedAmount = parseFloat(amount) * parseFloat(decimal);
    const trueAmount = parseFloat(amount) + addedAmount;
    return parseFloat(amount) + addedAmount;
  };

  const addPercentageAndTrueAmount = (amount) => {
    const decimal = state.fundRaiser?.charge ? state.fundRaiser?.charge / 100 : 0;
    const addedAmount = parseFloat(amount) * parseFloat(decimal);

    if(state.fundRaiser.entity.entityCountry === "South Africa"){
      return parseFloat(amount) + addedAmount;
    }else{
      const trueAmount = parseFloat(amount) + addedAmount;
      const globalRate = state?.globalSettings?.USDTOZARRATE;
      const convertedAmount = trueAmount*globalRate;
      return convertedAmount;
    }
  };

  const hasReminder = (amount) => {

    if (!amount) {
      return true;
    }

    if (parseInt(amount) <= 0) {
      return true;
    };

    const sharePrice = state.fundRaiser?.entity?.sharePrice || 0;
    const answer = (amount % sharePrice === 0);
    setDisabled(answer);
    return answer;
  };

  const [realAmount, setRealAmount] = useState(0);

  return (
    <div>
      <Navbar />
      <div className="invest-background-container">
        <div className='invest-main' >
          <div style={{marginTop:'25%'}}>
           <p  style={{ color: '#47868a',fontSize:16,marginBottom:'10%',fontWeight:'800',fontFamily:'Montserrat'}}>BOARD | MANAGEMENT | SHAREHOLDERS | PROSPECTUS</p> 
          <p className='title' style={{ color: '#000',}}>Creating value through local and diaspora collaborations</p>
          <p className="desc-2" style={{ color: '#000' }}>
          Diaspora Kapita(DK) is an investment company that was established August 2014 in South Africa. 
          The company was founded by a group of 13 Zimbabweans. The company currently has 30 shareholders 
          and has investments in South Africa and Zimbabwe in the following areas : financial services, 
          agriculture, contract mining , funeral services  and fintech. DK has a permanent staff compliment 
          of 200 people and more than 150 sales agents.
          Historically DK has delivered 7x return within 7 years and 5x return for NAFUICO within 5 years.
          DK is carving out its financial services interests into BridgeFort Capital, do a private placement 
          between in November 2023 -January 2024 and on Victoria Falls Stock Exchange by end of Q1 2024.
          </p>
          <div style={{ display: 'flex', marginBottom: 20, marginTop: 20, justifyContent: 'space-between' }}>
            <section className='invest-target'>
              <p style={{ color: '#000', fontSize: 20, margin: 0 }}>Investment Target</p>
              <p style={{ color: '#000', fontWeight: '600' }}>{state.currency}{currencyFixer(state.fundRaiser?.fundraisingGoal || 0)}</p>
            </section>
            <hr style={{ height: 70, backgroundColor: '#000', width: 2 }}></hr>
            <section className='invest-target'>
              <p style={{ color: '#000', fontSize: 20, margin: 0 }}>Investment Raised</p>
              <p style={{ color: '#000', fontWeight: '600' }}>{state.currency}{currencyFixer(state.totalRaised || 0)}</p>
            </section>
          </div>
          <ProgressBar
            className="invest-wrapper"
            completed={state?.percentageProgress}
            customLabel={state?.percentageProgress + '%'}
          />
          <strong><p className="title-2" style={{ marginTop: 20, marginBottom: 20, fontSize: 18 }}>
          Investing in BridgeFort Capital provides exposure to two distinct opportunities:
          </p></strong>
          <ul>
            <li style={{ fontSize: 15 }}>
              Consolidation of funeral services in South Africa (funeral cover and funeral services).
            </li>
            <li style={{ fontSize: 15 }}>
              Diaspora Market (financial services, construction services, etc.).
            </li>
          </ul>
          <p className="desc-2" style={{ color: '#000' }}>
          BridgeFort Capital is also exploring the possibility of extending its offerings to specific African countries.
          </p>
          </div>
        </div>
      </div>
      {!isLoading && !state.fundRaiser && <div className='invest-main'>
        <p className='title' style={{ color: '#000' }}>Committed to growth</p>,
        <p className="desc" style={{ color: '#000' }}>We are unwaveringly committed to fostering investment growth, diligently seeking promising opportunities, and advocating sustainable fiscal strategies to maximize our stakeholders' profits and ensure long-term financial stability.
        </p>
        <div style={{ marginTop: 20 }}>
          <a href='https://id.tsigiro.com' target='_blank'>
            <button className='btn btn-secondary' style={{ marginTop: 20, width: 'auto' }}>
              Sign in and start investing
            </button>
          </a>
        </div>
      </div>}
      {/* <div className='currency-switcher'>
              <section onClick={()=>setCurrency('USD')} className={`currency-item ${currency==='USD' && 'selected'}`}>Invest Now</section>
              <section onClick={()=>setCurrency('ZAR')} className={`currency-item ${currency==='ZAR' && 'selected'}`}>ZAR</section>
          </div> */}
      <div className='invest-button' style={{ zIndex: 10 }}>
        <a href='#invest'>
          <section>Invest Now</section>
        </a>
      </div>
      <div className='capital-structure'>
        <div>
          <div className='text-overline' style={{ textAlign: 'center', marginTop: 10 }}>Company Structure</div>
          <p className='title' style={{ color: '#000', textAlign: 'center', fontSize: 25 }}><strong>{state.fundRaiser?.entity?.entityName}</strong></p>
        </div>
        <div className='rule-container' style={{ justifyContent: 'center', width: '100%', marginBottom: 50 }}>
          {/* #e3944a */}
          <hr className='rule1' style={{ backgroundColor: '#dfb659', width: 150 }}></hr><hr style={{ backgroundColor: '#e3944a', width: 150 }} className='rule1'></hr><hr style={{ backgroundColor: '#47868a', width: 150 }} className='rule1'></hr>
        </div>
        <div className="structure">
        <CompanyStructure/>
        </div>
        <div className="mobile-structure">
          <img src={News} />
        </div>
      </div>
      <div className='capital-structure'>
        <div>
          <div className='text-overline' style={{ textAlign: 'center', marginTop: 10 }}>Company Structure</div>
          <p className='title' style={{ color: '#000', textAlign: 'center', fontSize: 25 }}><strong>NAFUICO</strong></p>
        </div>
        <div className='rule-container' style={{ justifyContent: 'center', width: '100%', marginBottom: 50 }}>
          {/* #e3944a */}
          <hr className='rule1' style={{ backgroundColor: '#dfb659', width: 150 }}></hr><hr style={{ backgroundColor: '#e3944a', width: 150 }} className='rule1'></hr><hr style={{ backgroundColor: '#47868a', width: 150 }} className='rule1'></hr>
        </div>
        <div className="structure">
        <NauficoStructure />
          </div>
          <div className="mobile-structure">
          <img src={NAFUICO} />
        </div>

      </div>
      <div className='invest-now-section' style={{ paddingTop: 80 }}>
        <div style={{ margin: 50 }}>
          <div className='text-overline' style={{ textAlign: 'center', marginTop: 10 }}>Opportunity!</div>
          <p className='title' style={{ color: '#000', textAlign: 'center' }}>FUNERAL INSURANCE AND SERVICES - SOUTH AFRICA</p>
        </div>
        <div className='grid-container'>
          <div className="grid-item" style={{ justifyContent: 'flex-start' }}>
            <p className='smt' style={{ color: '#000', fontSize: 20, fontWeight: '500' }}>1. The Opportunity</p>
            <div className="grid-flex">
              <section className='circular-chat'>
                <p className='ttle'>US$ 1,35<br /> Billion</p>
                <p className='des'>AGWP</p>
                <p className='smt'>(Funeral Cover)</p>
              </section>
              <p className='plus'>+</p>
              <section className='circular-chat'>
                <p className='ttle'>US$ 558<br /> Million</p>
                <p className='smt'>Annual Funeral <br />Services Industry</p>
              </section>
            </div>
            <p className='smt' style={{ color: '#004aad', fontSize: 10 }}>Source: ASISA, Dec 2022.</p>
          </div>
          <div className='grid-item' style={{ justifyContent: 'flex-start' }}>
            <p className='smt' style={{ color: '#000', fontSize: 20, fontWeight: '500' }}>2. Our Ambitions</p>
            <div className="grid-flex" style={{ marginTop: 30 }}>
              <section className='square-chat'>
                <p className='des' style={{ margin: 0, fontWeight: '600' }}>VISION</p>
                <p className='des' style={{ margin: 0, fontWeight: '600' }}>2028</p>
              </section>
              <section className='list-section'>
                <ul style={{ margin: 0 }}>
                  <li>
                    1 million - lives under cover
                  </li>
                  <li>US$ 3.2 million - in monthly gross premiums</li>
                  <li>US$ 38 million - Annual gross written premiums</li>
                  <li>US$ 110 million - Total gross written premiums over 5 years</li>
                  <li>US$ 1.3 million - Monthly funeral services income </li>
                </ul>
              </section>
            </div>
          </div>
          <div className='grid-item' style={{ justifyContent: 'flex-start' }}>
            <p className='smt' style={{ color: '#000', fontSize: 20, fontWeight: '500' }}>3. Our Nafuico Track Record</p>
            <ul style={{ color: '#004aad', marginTop: 40 }}>
              <li>Est. 2017 (Operations in 2018)</li>
              <li>US$ 3.7 million - Annual Gross Premium (in 4 years)</li>
              <li>US$ 3.5 million - Funding</li>
              <li>4 Province presence in South Africa</li>
              <li>38 Branches.</li>
              <li>104,000+ lifes Covered</li>
            </ul>
          </div>
          <div className='grid-item' style={{ justifyContent: 'flex-start' }}>
            <p className='smt' style={{ color: '#000', fontSize: 20, fontWeight: '500' }}>4. Our Strategy</p>
            <img src={Strat} style={{ height: 250 }} />
            <p style={{ margin: 0 }}><span style={{ color: '#004aad' }}>1 Million </span>lives in <span style={{ color: '#004aad' }}>5 years</span></p>
            <p style={{ margin: 0, fontSize: 10 }}>(1 Jan 2024 - 31 Dec 2028)</p>
          </div>
        </div>
      </div>
      <div className='tsig-now-section' style={{ backgroundColor: "#fafafa", padding: 20 }}>
        <div style={{ margin: 50 }}>
          <div className='text-overline' style={{ textAlign: 'center', marginTop: 10 }}>Opportunity!</div>
          <p className='title' style={{ color: '#000', textAlign: 'center' }}>TSIGIRO USEKELO - THE DIASPORA OPPORTUNITY</p>
        </div>
        <div className="invest-flex" >
          <div className="centered" >
            <section className='title-container'>
              <p className='section-title'>DIASPORA BOND</p>
              <ul>
                <li>Pension Funds</li>
                <li>Institutional Investors</li>
              </ul>
            </section>
            <img src={DownArrow} style={{ height: 50, width: 50 }} />
            <section className='title-container'>
              <p className='section-title'>MARKET SIZE</p>
              <ul>
                <li>5-10% of the Diaspora Mortgage Market</li>
                <li>100-200k Mortgages</li>
                <li>US$ 4-8 Billion (addressable book)</li>
              </ul>
            </section>
            <img src={DownArrow} style={{ height: 50, width: 50 }} />
            <section className='title-container-flex'>
              <section>
                <img src={Size} style={{ height: 70, width: 50 }} />
                <p style={{ fontSize: 10 }}><span style={{ fontWeight: '700' }}>2-3 million</span> diasporian Zimbabwean</p>
              </section>
              <img src={RightArrow} style={{ height: 50, width: 50, marginBottom: 40 }} />
              <section>
                <img src={Money} style={{ height: 70, width: 70, alignSelf: 'center' }} />
                <p style={{ fontSize: 10 }}><span style={{ fontWeight: '700' }}>US$2 billion</span> Annual Remmitance FY 2022</p>
              </section>
            </section>
          </div>
          <div className='right-arrow'>
          <img src={RightArrow} style={{ height: 50, width: 50, marginBottom: 40 }} />
          </div>
          <div className='down-arrow'>
          <img src={DownArrow} style={{ height: 50, width: 50, marginBottom: 40 }} />
          </div>
          <div className="centered">
            <section className='title-container'>
              <p className='section-title-bordered'>ENABLERS</p>
            </section>
            <img src={DownArrow} style={{ height: 50, width: 50 }} />
            <img src={Sendhome} style={{ height: 30 }} />
            <div className="row" style={{ marginTop: 10 }}>
              <img src={TwentyFirst} style={{ height: 35 }} />
              <img src={Agrostrong} style={{ height: 35 }} />
            </div>
            <div className="row" style={{ marginTop: 10 }}>
            <img src={Tsigiro} style={{ height: 50 }} />
            <img src={Jamboo} style={{ height: 50 }} />
            </div>
          </div>
          <div className='right-arrow'>
          <img src={RightArrow} style={{ height: 50, width: 50, marginBottom: 40 }} />
          </div>
          <div className='down-arrow'>
          <img src={DownArrow} style={{ height: 50, width: 50, marginBottom: 40 }} />
          </div>
          <div className="centered">
            <section className='title-container'>
              <p className='section-title-bordered'>ENABLERS</p>

              <ul>
                <li>Property in Zimbabwe</li>
                <ul>
                  <li>Marondera - 400 x 2000m<sup>2</sup></li>
                  <li>Harare - Chivero - 800 x 500m<sup>2</sup></li>
                  <li>Harare - Pomona 50 x 500m<sup>2</sup></li>
                  <li>Harare - Burial Site: 40, 000 graves</li>
                </ul>
              </ul>
            </section>
            <img src={DownArrow} style={{ height: 50, width: 50 }} />
            <div className="row-circle">
              <section className='circular-chat-small'>
                <p style={{ fontSize: 9, fontWeight: '600', margin: 0 }}>ASSET MANAGEMENT</p>
                <p style={{ fontSize: 29, margin: 0, fontWeight: '800' }}>US$</p>
                <p style={{ fontSize: 9, fontWeight: '800', margin: 0 }}>100 - 200 m</p>
                <p style={{ fontSize: 6, fontWeight: '600', margin: 0 }}>Annual Inflows</p>
              </section>
              <section className='circular-chat-small second-circle' >
                <p style={{ fontSize: 9, fontWeight: '600' }}>INSURANCE</p>
                <p style={{ fontSize: 10, fontWeight: '800', margin: 0 }}>Diaspora Funeral</p>
                <p style={{ fontSize: 10, fontWeight: '800', margin: 0 }}>Cover</p>
                <p style={{ fontSize: 15, fontWeight: '600', margin: 0 }}>+</p>
                <p style={{ fontSize: 10, fontWeight: '800', margin: 0 }}>Life cover</p>
              </section>
            </div>
            <section className='circular-chat-small last-circle'>
              <p style={{ fontSize: 9, fontWeight: '600' }}>DIASPORA BANKING</p>
              <p style={{ fontSize: 15, fontWeight: '800', margin: 0 }}>Mortgages</p>
            </section>
          </div>

        </div>

        <div className='tsig-now-section' style={{ height: 'auto' }}>
          <div style={{ margin: 50 }}>
            <div className='text-overline' style={{ textAlign: 'center', marginTop: 10 }}>Financial Perfomance!</div>
            <p className='title' style={{ color: '#000', textAlign: 'center' }}>Key Metrics and Projections</p>
          </div>
          <div className='grid-container'>
            <div className="grid-item" >
              <KPIChart />
            </div>
            <div className="grid-item" >
              <RevenueChart />
            </div>
            
            <div className="grid-item">
              <div className='row-circle'>
                <section className='circular-chat'>
                  <p className='ttle'>297%</p>
                  <p className='des'>Avg annual </p>
                  <p className='des'>revenue growth</p>
                  <p className='des'>Projection</p>
                  <p className='smt'>2024-2028</p>
                </section>
                <section className='circular-chat'>
                  <p className='ttle'>44%</p>
                  <p className='des'>Avg Annual Gross </p>
                  <p className='des'>Profit Margin</p>
                  <p className='des'>Projection</p>
                  <p className='smt'>2024-2028</p>
                </section>
              </div>
              <ol style={{ marginTop: 20 }}>
                <li>Actuals & Projections in the Consolidated Income Statement exclude SendHome, Linar Life, and Jamboo which will all be
                  accounted for as associates.
                </li>
                <li>An Annual Revenue Growth projection surge of 1,276% for 2024 attributed to capital raise and listing initiatives The
                  Revenue Growth normalizes from 2025 onwards at an annual average of 53%.</li>
                <li>Annual GP Margin is projected at an average of 44% from 2024 onwards with projections above average during the
                  2026 -2028 period.</li>
              </ol>
            </div>
            {/* <div className="grid-item">
              <DividendsChart />
            </div> */}
          </div>

        </div>
        {isLoading && <div className='invest-main'><span class="loader"></span></div>}
        {!isLoading && state.fundRaiser && <div className='invest-now-section' style={{ paddingTop: 80 }}>

          <div style={{ margin: 50 }}>
            <div className='text-overline' style={{ textAlign: 'center', marginTop: 10 }}>Get started now!</div>
            <p className='title' style={{ color: '#000', textAlign: 'center' }}>Investment options</p>
            <p className='title' style={{ color: '#000', textAlign: 'center',fontSize:13 }}>The listing price is US${state.fundRaiser?.entity?.sharePrice} per share and online transaction fee of {state.fundRaiser?.charge}% </p>
          </div>
          <div className='grid-container' id='invest'>
            {
              (state.fundRaiser?.investmentOptions || []).map((x, index) => {
                const amount = addPercentageToAmount(x.amount);
                const netcashAmount = addPercentageAndTrueAmount(x.amount);
                return <div className='grid-item' key={index}>
                  <div className='text-overline' style={{ textAlign: 'center', marginTop: 10, color: '#000', fontWeight: 600 }}>{x?.title}</div>
                  <p className='title' style={{
                    color: '#000', textAlign: 'center', marginTop: 30, fontSize: 50, textDecoration: 'underline',
                    textDecorationColor: '#C0C0C0',
                    textDecorationThickness: 10,
                    fontWeight: '700'
                  }}>{state?.currency}
                    {currencyFixer(addPercentageToAmount(x.amount) || 0)}
                  </p>
                  <p className="desc" style={{ color: '#000', textAlign: 'center' }}>
                    You get <strong>{sharePriceCalculator(x.amount)}</strong> shares in <strong>{state.fundRaiser?.entity?.entityName}</strong>
                  </p>
                  <form
                    name="makeP"
                    id="makeP"
                    method="POST"
                    action="https://paynow.netcash.co.za/site/paynow.aspx"
                  >
                    <input type="text" name="m1" value="54580e3d-792b-4d97-8c9b-991f287063fd" hidden edit />
                    <input type="text" name="m2" value="24ade73c-98cf-47b3-99be-cc7b867b3080" hidden edit />
                    <input type="text" name="p2" value={transaction?.id} hidden edit />
                    <input type="text" name="m4" value={transaction?.encoded} hidden edit />
                    <input type="text" name="m5" value={state?.fundRaiser?._id} hidden edit />
                    <input type="text" name="m6" value={x.amount} hidden edit />
                    <input type="text" name="p3" value={state.fundRaiser?.entity?.entityName} hidden edit />
                    <input type="text" name="p4" value={netcashAmount} hidden edit />
                    <button type='submit' className='btn btn-secondary'>
                      Invest Now
                    </button>
                  </form>
                </div>
              })
            }
          </div>
        </div>}
        {!isLoading && state.fundRaiser && <div className="custom-investment">
          <div >
            <div className='text-overline' style={{ textAlign: 'center', marginTop: 10 }}>Get started now!</div>
            <p className='title' style={{ color: '#000', textAlign: 'center' }}>Invest with custom amount</p>
          </div>
          <div className='input-container'>
            <span style={{ fontSize: 50, fontWeight: '700' }}>US$</span><input type="number"
              onWheel={(e) => e.target.blur()}
              onChange={(e) => {
                setCustomAmount(addPercentageToAmount(e.target.value));
                setNetCashAmount(addPercentageAndTrueAmount(e.target.value));
                hasReminder(e.target.value);
                setRealAmount(e.target.value);
              }}
              min={100} /><span style={{ fontSize: 50, fontWeight: '700' }}>.00</span>
          </div>
          {
            customAmount > 0 && <p>You get <strong>{sharePriceCalculator(realAmount)}</strong> shares in  <strong>{state.fundRaiser?.entity?.entityName}</strong> for <strong>{state.currency + " " + currencyFixer(customAmount || 0)}</strong>  including transaction charges </p>
          }
          <form
            name="makeP"
            id="makeP"
            method="POST"
            action="https://paynow.netcash.co.za/site/paynow.aspx"
          >
            <input type="text" name="m1" value="54580e3d-792b-4d97-8c9b-991f287063fd" hidden edit />
            <input type="text" name="m2" value="24ade73c-98cf-47b3-99be-cc7b867b3080" hidden edit />
            <input type="text" name="p2" value={transaction?.id} hidden edit />
            <input type="text" name="m4" value={transaction?.encoded} hidden edit />
            <input type="text" name="m5" value={state?.fundRaiser?._id} hidden edit />
            <input type="text" name="m6" value={realAmount} hidden edit />
            <input type="text" name="p3" value={state.fundRaiser?.entity?.entityName} hidden edit />
            <input type="text" name="p4" value={netcashAmountValue} hidden edit />
            <button type='submit' disabled={!disabled} className='btn btn-secondary' style={{ marginTop: 10, backgroundColor: disabled ? 'green' : '#919191' }}>
              Invest Now for {"US$ " + currencyFixer(customAmount || 0)}
            </button>
          </form>
          {!disabled && parseInt(customAmount) > 0 && <p >Total shares should not have a decimal</p>}
        </div>}
      </div>
      <InvestmentsFaqs />
      <Footer />
    </div>

  )
}

export default Invest;