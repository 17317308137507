import {faMinus, faPlus} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React,{useState} from 'react';
import {FAQS} from '../../constants/FAQS';

const FAQ = () => {

    const [selectedFaqs,setSelectedFaqs] = useState({})

  return (
        <div className='faq-container'>
            <div className='faq-left'>
                <p className='title'>FAQS</p>
                <p> If your question wasn’t answered or not described in depth, feel free
                 to contact our team for assistance.</p>
                {/* <button className='btn btn-primary' style={{margin:0}}>
                        Contact Us
                </button> */}
            </div>
            <div className='faq-right'>
                {
                    FAQS.map((x, index)=>{
                        return <div key={index} className='faq-item '>
                                    <div className='faq-selector'>
                                    <p className='title'>{x.title}</p>
                                    <FontAwesomeIcon 
                                    onClick={()=>{
                                        setSelectedFaqs({...selectedFaqs,[index]:selectedFaqs[index]?!selectedFaqs[index]:true});
                                    }}
                                    icon={selectedFaqs[index] ? faMinus : faPlus }/>
                                    </div>
                                    <div className={`faq-description ${ selectedFaqs[index] ? "faq-visibility" : "faq-non-visibility"}`}>
                                    <p>{x.description}</p>   
                                    </div>
                               </div>
                    })
                }
                
               
            </div>  
        </div>
  );

}

export default FAQ