import React, { useEffect, useState } from 'react';
import {HeroItems} from '../../constants/Hero';
import HeroTemplate from './HeroTemplate';


const Hero = () => {

  const [selectedHero,setSelectedHero] = useState(0);

  return (
    <div className='hero-container'>
        <HeroTemplate
            {...HeroItems[selectedHero]}
            selectedHero={selectedHero}
            setSelectedHero={setSelectedHero}
        />
    </div>
  )
}

export default Hero