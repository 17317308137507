export const FAQS = [
    {
        title:'What do I need to become part of the Tsigiro Usekelo Trust Fund?',
        description:` You need the following to have ID on the platform;
                Email Address or Phone Number,
                ID Document and 
                Proof of Address
                `
    },
    {
        title:'What are the benefits of joining the Tsigiro Usekelo Trust Fund',
        description:`You benefit in the following ways;\n
                1. Your investments earn a return,\n
                2. The deposit required by the financial partners of the Trust Fund is reduced compared to going to them directly,\n
                3. You get an extended/prolonged repayment period,\n
                4. You get access to 21st Century Life Funeral Cover (no waiting period if you are switching from another service provider),\n
                5. You get access to Life Cover (in the event of death, your property is 100%paid-up and handed over to your nominated beneficiaries).\n
                `
    },
    {
        title:'What if I already have a property?',
        description:'You can be an investor on the platform that allows for your money to accumulate a return.'
    }
]